import * as MSP from "../DTOs/Types";

interface KeyValuePair {
  const: MSP.Constituency;
  region: MSP.Region;
}

export class ConstituencyToRegionMap {
  public static Collection: KeyValuePair[] = [
    {
      const: MSP.Constituency.Paisley,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.MotherwellAndWishaw,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.NaHEileananAnIar,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.RenfrewshireSouth,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.Dumbarton,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.MidlothianNorthAndMusselburgh,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.ClackmannanshireAndDunblane,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.AberdeenshireWest,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.Clydesdale,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.Eastwood,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.GallowayAndWestDumfries,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.KilmarnockAndIrvineValley,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.EdinburghWestern,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.AlmondValley,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.Stirling,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.PerthshireSouthAndKinrossShire,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.EdinburghCentral,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.EdinburghEastern,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.AngusSouth,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.GlasgowCathcart,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.InvernessAndNairn,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.Cowdenbeath,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.EastKilbride,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.DundeeCityWest,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.SkyeLochaberAndBadenoch,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.CarrickCumnockAndDoonValley,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.CunninghameNorth,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.MidFifeAndGlenrothes,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.AngusNorthAndMearns,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.EastLothian,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.Rutherglen,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.CumbernauldAndKilsyth,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.Linlithgow,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.EdinburghSouthern,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.GlasgowAnniesland,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.Moray,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.UddingstonAndBellshill,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.EdinburghPentlands,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.FalkirkEast,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.CoatbridgeAndChryston,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.RenfrewshireNorthAndWest,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.StrathkelvinAndBearsden,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.EdinburghNorthernAndLeith,
      region: MSP.Region.Lothian
    },
    {
      const: MSP.Constituency.CunninghameSouth,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.AberdeenshireEast,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.GlasgowShettleston,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.FalkirkWest,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.Orkney,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.AberdeenDonside,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.GlasgowProvan,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.GreenockAndInverclyde,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.Dumfriesshire,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.AirdrieAndShotts,
      region: MSP.Region.CentralScotland
    },
    {
      const: MSP.Constituency.ClydebankAndMilngavie,
      region: MSP.Region.WestScotland
    },
    {
      const: MSP.Constituency.NorthEastFife,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.DundeeCityEast,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.CaithnessSutherlandAndRoss,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.ArgyllAndBute,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.Ayr,
      region: MSP.Region.SouthScotland
    },
    {
      const: MSP.Constituency.Dunfermline,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.BanffshireAndBuchanCoast,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.AberdeenCentral,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.GlasgowSouthside,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.PerthshireNorth,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.Kirkcaldy,
      region: MSP.Region.MidScotlandAndFife
    },
    {
      const: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      region: MSP.Region.NorthEastScotland
    },
    {
      const: MSP.Constituency.GlasgowKelvin,
      region: MSP.Region.Glasgow
    },
    {
      const: MSP.Constituency.Shetland,
      region: MSP.Region.HighlandsAndIslands
    },
    {
      const: MSP.Constituency.GlasgowPollok,
      region: MSP.Region.Glasgow
    }
  ];
}
