import React from "react";
import "../App.css";

class BackgroundPage extends React.Component<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className="App">
        <header className="App-header"></header>
        <p>
          Despite much progress around LGBT+ rights over the past decade, there
          is a real threat to LGBT+ people throughout their lives from
          conversion therapy. Conversion therapy, the forced conditioning
          against a person’s sexuality or gender identity, has damaged
          generations of LGBT+ young people and adults and continues to do so.
          This incredibly harmful practice especially targets LGBT+ people when
          they are at their most vulnerable.
        </p>
        <p>
          Stonewall’s Unhealthy Attitudes report (2015) found 10 per cent of
          health and care staff had witnessed colleagues expressing that
          lesbian, gay and bi people can be “cured” of their sexual orientation.
          This leads to poor treatment of lesbian, gay, bi and trans people
          within health and social care services.
        </p>
        <p>
          A 2009 survey of over 1,300 accredited mental health professionals
          found that more than 200 had offered some form of conversion therapy,
          with 35 per cent of patients referred to them for treatment by GPs and
          40 per cent treated inside an NHS practice.
        </p>
        <p>
          As recently as 2018, the Catholic Diocese of Paisley's online
          resources included several resources developed by advocates for gay
          ‘cure’ therapy on its website. One pamphlet hosted on the church
          website encourages parents to consider sending homosexual children for
          conversion therapy.
        </p>
        <p>
          The 2018 Faith &amp; Sexuality Survey from the Ozanne Foundation
          found: Over one in five respondents (20.7%) had “been advised to
          consider attempts to change” their sexual orientation and just over
          one in seven (14.9%) had “voluntarily considered” it. Concerningly,
          3.5% of LGBT+ respondents had “been forced to go through attempts to
          change” their sexual orientation. In total, 11.4% of respondents said
          they had actual experience of attempting to change their sexual
          orientation. This was most common amongst those who defined themselves
          as "lesbian" (25.1%), "gay" (21.4%) and "same-sex attracted" (23.2%).
        </p>
        <p>
          However, 74% who attempted to change their sexual orientation said
          that "it did not work for me and I do not believe it works for
          others". Of those respondents with experience of attempting to change
          their sexual orientation, only 30.1% said they had "gone on to live a
          happy and fulfilled life"; 69.9% were unhappy and unfulfilled, and
          46.1% stated that “I have found it hard to accept myself for who I
          am".
        </p>
        <p>
          Significantly, almost two-thirds, 58.8%, had "suffered from mental
          health issues" as a result of the conversion therapy, and nearly a
          third, 31.2%, said, “I have sought counselling to help me recover from
          it". Most of those who said they had had mental health issues had
          suffered from anxiety and depression (a total of 252 people), with
          women (65.1%) more likely than men (53.8%) to state that they actually
          "required medication". The most frequent response under “Other" was
          the fact that respondents had been diagnosed with "Post Traumatic
          Stress Disorder". The most frightening statistic is that nearly a
          third, 32.4%, had "attempted suicide". In addition:
        </p>
        <ul>
          <li>
            Two-thirds of those with mental health issues, 68.7%, said they had
            "suicidal thoughts".
          </li>
          <li>40.2% "self-harmed"</li>
          <li>24.6% suffered from "eating disorders"</li>
        </ul>
        <p>
          Over half of respondents said that they thought sexual orientation
          change therapy "should be made a criminal offence“ and a quarter,
          24.2%, said that they thought "it should not be made a criminal
          offence, but should be stopped". Only 5.2% thought "it should be
          allowed".
        </p>
        <p>
          These responses clearly show that there is a significant public health
          case for banning conversion therapy. The experiences of those who were
          put through this practice show that enforcing this ban should fall
          under criminal law. Both of these areas fall within the devolved
          powers of the Scottish Parliament.
        </p>
        <p>
          Despite committing to ending this practice years ago, the British
          Government has not acted. Instead recently women's and equalities
          minister Elizabeth Truss MP has adopted, what we believe is, the
          anti-trans rhetoric of hate groups in a way that will only ensure the
          expansion of providers and efforts to pressure people with trans
          identities to undergo this horrific practice.
        </p>
        <p>
          It is clear this issue isn't going away, even as a redoubled campaign
          against transgender identities ensures that practices such as
          conversion therapy are as much of a threat than ever. Given this
          information, it is clear that conversion therapy is a risk to public
          health, especially the mental health of vulnerable young adults and
          children. There is broad support for its criminalisation by those who
          have been subjected to it, and, as healthcare and criminal justice
          matter, we believe it to be within the scope of the Scottish
          Government’s powers to ensure this practice is brought to an end.
        </p>
      </div>
    );
  }
}

export default BackgroundPage;
