import * as MSP from "../DTOs/Types";

export class MSPs {
  public static MSPsCollection: MSP.MSP[] = [
    {
      Name: "	John Mortimer	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lee William	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Dunsmore	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Pressley	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jack Malcolm	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.HighlandsAndIslands,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Nichol	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Leckie	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Mann	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Duff	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Callum Buchanan	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Ferguson	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Simon Bellford	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Watson	",
      Party: MSP.Party.AbolishTheScottishParliamentParty,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim Walker	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lynne Anderson	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Margaret Lynch	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tasmina Ahmed-Sheikh	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alisa Gray	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lynn McMahon	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michelle Ferns	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Shahid Farooq	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Craig Berry	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Josh Robertson	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Judith Reid	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kirk Torrence	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Arthur	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christina Hendry	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Irshad Ahmed	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kenny MacAskill	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Eva Comrie	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim Eadie	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neale Hanvey	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stephanie Reilly	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Salmond	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Brian Topping	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Dot Jessiman	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Heather McLean	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Corri Wilson	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Cynthia Guthrie	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Laurie Flynn	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Suzanne Blackley	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Caroline McAllister	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christopher McEleny	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Delia Henry	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ellen McMaster	",
      Party: MSP.Party.AlbaParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Billy Ross	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jonathan Stanley	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lachlan MacNeil	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mary Devlin	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Matt Alexander	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sandra Hill	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sandy Smart	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christ Creighton	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christian McNeill	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Christopher Mulholland	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jean Anne Mitchell	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Khaleel Mohammed	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Niall Fraser	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ricky Morton	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alastair Kennedy	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Donald Boyd	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Moira Ramage	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Patricia Watson	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Bradburn	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Burrows	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robbie Munro	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alan Hogg	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andy Macauley	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Charlotte Morley	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Hamilton	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Derek Clark	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mike Knox	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Parvinder Singh	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Brian Henderson	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graham Dawson	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Stewart	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Imam Khan	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Glen	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Linda Holt	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Saint	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Arthur D Keith	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ben Stones	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bobby Ferguson	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Danielle Millar	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John McPhee	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Kelbie	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Scott Fenwick	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bruce Halliday	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Galloway	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jamie Blackett	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim Grindlay	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kirsteen Mitchell	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Malcolm MacDonald	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Calum Robertson	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Catherine McCall	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Griffiths	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul McLafferty	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rhona Cameron	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Aikman	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Senol Jason Ali	",
      Party: MSP.Party.AllForUnity,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Johnnie Hunter	",
      Party: MSP.Party.CommunistPartyOfBritain,
      Region: MSP.Region.Glasgow,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Matthew Waddell	",
      Party: MSP.Party.CommunistPartyOfBritain,
      Region: MSP.Region.Lothian,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daniel Lambe	",
      Party: MSP.Party.CommunistPartyOfBritain,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Claire McClaren	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Colin McMillan	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Dave Frankland	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Di Meechan	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Irvine	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Louise Bannigan	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Carol Dobson	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Mills	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Diane Hodge	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mary Steven	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Roderick Nicoll	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stephen Lonsdale	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tina McCaffery	",
      Party: MSP.Party.FreedomAlliance,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Anne McCloskey	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Emma Idzidowska	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gary Cheesman	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Phil Breed	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jon Pullman	",
      Party: MSP.Party.FreedomAlliance,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Cara Wase	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Patricia McCann	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Eren Sinclair	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kathleen Elliott	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lisa Brackenridge	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stuart Cairns	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jason Duncan	",
      Party: MSP.Party.FreedomAlliance,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Diane Mathieson	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kirsty Miller	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sadie Cubitt	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Amanda McConechy	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gillian Jamieson	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Joy Rivett-Gill	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mandy Blackman	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Grant Stirling	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jill McGowan	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maria Smith	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mark Turnbull	",
      Party: MSP.Party.FreedomAlliance,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Carol Dobson	",
      Party: MSP.Party.FreedomAlliance,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paddy Hogg	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.CentralScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Craig Ross	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.Glasgow,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daniel Donaldson	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.Glasgow,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andy Wightman	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.HighlandsAndIslands,
      Support: MSP.Support.Yes,
      Statement:
        "Yes, I support this campaign though as with all campaigns that involve creating criminal offences, further detailed thought is needed to ensure that people are not inadvertently caught by the criminal law. As with everything there are issues that will be brought up in consultation - for example how to protect health professionals etc. I think the state of Victoria in Australia has brought in some good legislation in this areas. So yes, I support the general principles set out in your request"
    },
    {
      Name: "	Hazel Mansfield	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.HighlandsAndIslands,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ashley Graczyk	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.Lothian,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin Keatings	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.MidScotlandAndFife,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mercy Kamanja	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.MidScotlandAndFife,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Geoffrey Farquharson	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Laura Marshall	",
      Party: MSP.Party.Independent,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Morrison	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.CunninghameSouth,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maurice Campbell	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.CunninghameSouth,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jimmy Dowson	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexander Roberton	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew Muir	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.Dumbarton,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David MacDonald	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.Eastwood,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bonnie Prince Bob	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.EdinburghCentral,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jesse Rae	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Peter Krykant	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.FalkirkEast,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Joseph Finnie	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.GlasgowPollok,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Greg Energy Advisor	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jayda Fransen	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Burleigh	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Callum MacMillan	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Peter Tait	",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.Shetland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Robertson	",
      Party: MSP.Party.IndependentGreenVoice,
      Region: MSP.Region.CentralScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alistair McConnachie	",
      Party: MSP.Party.IndependentGreenVoice,
      Region: MSP.Region.Glasgow,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Tallach	",
      Party: MSP.Party.IndependentGreenVoice,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maxwell Dunbar	",
      Party: MSP.Party.IndependentGreenVoice,
      Region: MSP.Region.SouthScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Inkster	",
      Party: MSP.Party.IndependentGreenVoice,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Deek Jackson	",
      Party: MSP.Party.LiberalParty,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Leo Kearse	",
      Party: MSP.Party.ReclaimParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alan Melville	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexander MacGregor	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ann Murray	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Danyaal Raja	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Hugh MacDonald Skinner	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew MacMillan	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christina MacMillan	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ellen MacMillan	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jamie-Lee McMillan	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kirsty MacMillan	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Catherine Mount	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kate Brownlie	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Les Durance	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sandra Skinner	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Derek Winton	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Iain Morse	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lesley MacDonald	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mev Brown	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexander Black	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Guy Addison	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Karen Morton	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kenneth Morton	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Otto Inglis	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Victoria McCann	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Carol MacDonald	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Greig Mair	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Whitelaw	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Cox	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Samantha Evans	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Kirkwood	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Corbett	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michelle Ballentyne	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	William Luke	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John McCallum	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martyn Greene	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Peter Sievwright	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Wayne Darnell	",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew Macdonald	",
      Party: MSP.Party.RestoreScotland,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Brian Nugent	",
      Party: MSP.Party.RestoreScotland,
      Constituency: MSP.Constituency.Shetland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David McHutchon	",
      Party: MSP.Party.RestoreScotland,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ewan Gurr	",
      Party: MSP.Party.RestoreScotland,
      Constituency: MSP.Constituency.DundeeCityWest,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Chic Brodie	",
      Party: MSP.Party.ScotiaFuture,
      Constituency: MSP.Constituency.Ayr,
      Region: MSP.Region.SouthScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andy Doig	",
      Party: MSP.Party.ScotiaFuture,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.Yes,
      Statement:
        "I work in addictions support in Renfrewshire South, and sadly because of historic stigma I have worked, over the last 16yrs with many LGBTQ+ people who developed a drug or alcohol problem through dealing with inhumane practices such as conversion therapy. I am proud to belong to a faith community, the Scottish Episcopal Church, which fully welcomes and accepts LGBTQ+ people into leadership roles as priests and bishops."
    },
    {
      Name: "	Ross Lambie	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gordon Macdonald	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Haroun Malik	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CumbernauldAndKilsyth,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graham Simpson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EastKilbride,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neil Benny	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.FalkirkEast,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stephen Kerr	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.FalkirkWest,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Meghan Gallacher	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Nathan Wilson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bryan Flannagan	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.UddingstonAndBellshill,
      Region: MSP.Region.CentralScotland,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ade Aibinu	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alix Mathieson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sandesh Gulhane	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Annie Wells	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowProvan,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Thomas Kerr	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowShettleston,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "I've been a huge supporter and advocate of the LGBTQ+ community and if elected in May would ensure that my support continues."
    },
    {
      Name: "	Kyle Thornton	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: "I am supportive of your campaign and wish you well with it."
    },
    {
      Name: "	Lynne Nailon	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Rutherglen,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lynn Nailon	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Donald Cameron	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.ArgyllAndBute,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Struan Mackie	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Edward Mountain	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tim Eagle	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Moray,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gavin Berkenheger	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sam Bown	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Orkney,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Nich Tulloch	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Shetland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jamie Halcro Johnston	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.SkyeLochaberAndBadenoch,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Douglas Ross	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ella Robertson McKay	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.HighlandsAndIslands,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Scott Douglas	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graham Hutchison	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghEastern,
      Region: MSP.Region.Lothian,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Callum Laidlaw	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gordon Lindhurst	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Miles Briggs	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Susan Webber	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghWestern,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Charles Kennedy	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Linlithgow,
      Region: MSP.Region.Lothian,
      RegionRank: 12,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Iain Whyte	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.MidlothianNorthAndMusselburgh,
      Region: MSP.Region.Lothian,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jeremy Balfour	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Malcolm Offord	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Marie-Clair Munro	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rebecca Fraser	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexander Stewart	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.ClackmannanshireAndDunblane,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Darren Watt	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Cowdenbeath,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kathleen Leslie	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Kirkcaldy,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David MacPhee	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rhona Metcalfe	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.NorthEastFife,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Murdo Fraser	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Liz Smith	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.PerthshireSouthAndKinrossShire,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Dean Lockhart	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Stirling,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Angus Forbes	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Moira Benny	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Roz McCall	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Douglas Lumsden	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenCentral,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Harriet Cross	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenDonside,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stewart Whyte	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenshireEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexander Burnett	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenshireWest,
      Region: MSP.Region.NorthEastScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Liam Kerr	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maurice Golden	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AngusSouth,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mark Findlater	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Philip Scott	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.DundeeCityEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tess White	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.DundeeCityWest,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alan Fakley	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 13,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Eleanor Price	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gillian Tebberen	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mason McIlreavy	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement:
        "I am an LGBT candidate and lobby the party internally on LGBT rights."
    },
    {
      Name: "	Sharon Dowey	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CarrickCumnockAndDoonValley,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Eric Holford	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Clydesdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement:
        "As a young man, I often listened to the music of Lou Reed. I was horrified to read of how his family and doctors attempted to “cure” his gender “problems” with violent and painful ECT and other methods. In this day and age one would like to think that those sort of attitudes and barbaric practices belong to ancient history. So, yes, I absolutely back your campaign. Everyone has the right to live their lives the way they feel most comfortable and at home, without fear or intimidation from the small minded attitudes of others."
    },
    {
      Name: "	Oliver Mundell	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Dumfriesshire,
      Region: MSP.Region.SouthScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Craig Hoy	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EastLothian,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rachael Hamilton	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Finlay Carson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Region: MSP.Region.SouthScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Brian Whittle	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Shona Haslam	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Allison	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alexandra Herdman	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Denerley	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 12,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Scott Hamilton	",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Pam Gosal	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jamie Greene	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CunninghameNorth,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	David Rocks	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.CunninghameSouth,
      Region: MSP.Region.WestScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maurice Corry	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Dumbarton,
      Region: MSP.Region.WestScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jackson Carlaw	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Eastwood,
      Region: MSP.Region.WestScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "This is an abhorrent practice that has no place in Scotland or the UK."
    },
    {
      Name: "	Caroline Hollins Martin	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Region: MSP.Region.WestScotland,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Russell Findlay	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Paisley,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Julie Pirone	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.RenfrewshireNorthAndWest,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Derek Stillie	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew Polson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Damian Timson	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AlmondValley,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Braden Davy	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AngusNorthAndMearns,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Scott	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Ayr,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rosalind McCall	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Dunfermline,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Abe Aibinu	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Euan Blockley	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowCathcart,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Grahame Cannell	",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Leo Lanahan	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.No,
      Statement: ""
    },
    {
      Name: "	Christine MacIver	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christopher Hendry	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Helen McEachen	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John McGrory	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Tortolano	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Laird	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Joshua Hall	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Margaret Paterson	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	William Neeson	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Dolores Hughes	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Willis	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      Support: MSP.Support.No,
      Statement:
        "If therapy is voluntarily requested by a consenting individual surely this transaction cannot be criminalised!I find it disturbing that your package of measures suggests awareness training is provided for religious leaders and an impact assessment of their teachings is undertaken."
    },
    {
      Name: "	Philipp Tanzer	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Phillip Tanzer	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Shena McLelland	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sophie Hendry	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Lucas	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Region: MSP.Region.Lothian,
      Support: MSP.Support.No,
      Statement: "I do not support your pledge."
    },
    {
      Name: "	Stephen Saunders	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Donald Marshall	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Anil Alexander	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Davina Saunders	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Carratt	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Linda Mair	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Lynch	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Bestwick	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Arthur	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Donaldson	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Shehla Arthur	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	WilliamStrachan	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Charles McEwan	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.No,
      Statement:
        "I believe that if a person wishes to receive conversion therapy, the state has no business telling them that they can't. We live in a democracy not in a Stalinist dictatorship."
    },
    {
      Name: "	Dorothy Yost	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Elizabeth Fabisiak	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sophie Hendry	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Theresa Gavin	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin L. Bell	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.RenfrewshireNorthAndWest,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.No,
      Statement: ""
    },
    {
      Name: "	Liam McKechnie	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.No,
      Statement:
        "I wish to reject and condemn wholeheartedly your pledge to end conversion therapy. I find it disturbing that you wish to take away freedoms from people that wish to seek help for unwanted sexual desires. Would you wish to take away someone’s ability to seek help for pornography addiction? That would seems to me to be the next logical step after you ban conversion therapy."
    },
    {
      Name: "	Cecilia Tortolano	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin Bell	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mary Toal	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Matthew Lynch	",
      Party: MSP.Party.ScottishFamilyParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Philip Holden	",
      Party: MSP.Party.ScottishFamilyParty,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Claire Williams	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gillian Mackay	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "As lead candidate for the Scottish Greens for Central Scotland I'd like to register my support for the campaign."
    },
    {
      Name: "	James Stuart Duffin	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Kyle Davidson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "I am greatly upset that something as horrific as conversion therapy is still a problem in 2021. Nobody should be forced to be somebody they aren't and we need to create a society that celebrates what makes us unique."
    },
    {
      Name: "	Patrick McAleer	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rosemary McGowan	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I am more than happy to support the demand for an end to conversion therapy."
    },
    {
      Name: "	Tom McLaughlin	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Patrick Harvie	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "Attempts to use conversion therapy of any form against LGBT+ people stem from prejudice. But masquerading as a bogus therapy gives it a unique ability to do harm through breach of trust. It is inherently a form of abuse, not therapy, and it should be outlawed."
    },
    {
      Name: "	Nadia Kanyange	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kim Long	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.GlasgowProvan,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Anthony Carroll	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support any effort to end conversion therapy. We have seen that we must have people in Holyrood actively working to end this, as well as actively prevent any attempts to legalise this abhorrence, and if I were elected as an MSP, I would be one of hopefully many voices that will work to end this for good."
    },
    {
      Name: "	Blair Anderson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Calum Mcintosh	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Dan Hutchison	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "I'm really pleased to see efforts going into a campaign to ban conversion therapy in Scotland, and if elected, this would be one of the many issues I would be championing in the first year of the new session."
    },
    {
      Name: "	Elaine Gallagher	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Ellie Gomersall	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement:
        "I'm writing to express my dedication to fighting for legislation to end conversion therapy. Conversion therapy is a horrific practice, and must be criminalised. I sign up the pledge, and fully support the criminal ban, as well as the other measures you list including support for survivors."
    },
    {
      Name: "	Emma Cockburn	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Holly Bruce	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement:
        "I support the campaign to end conversion therapy and to bring legislation to the next Parliament to ban this inhumane act."
    },
    {
      Name: "	Jon Molyneux	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "I'm pleased to give my support to the campaign to end conversion therapy in Scotland."
    },
    {
      Name: "	Ariane Burgess	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Anne Thomas	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I have pledged to support the campaign to bring an end to conversion therapy in Scotland. I will support a bill in Scottish Parliament that will establish a criminal ban on all promotion, provision, and coercion of others into undergoing LGBT+ conversion therapy. I will support further measures to support victims of this abusive practice and community efforts to end the practice."
    },
    {
      Name: "	Chris Ballance	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Debra Nicolson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: "I would like to add my name to this list in support."
    },
    {
      Name: "	Fabio Villani	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Isabella Sumsion	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Lisa Jane Mead	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "It was shocking to me to read your background report about the kind of conversion therapy practices that still go on in Scotland. I agree that the seriousness of this merits a criminal sanction, as it is contrary to an individual's human rights and potentially deeply damaging for an individual's sexual orientation to be challenged in this misguided way. Wishing you well with your campaign and hoping for a swift end to this repugnant practice."
    },
    {
      Name: "	Luna Martin	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Phyl Meyer	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to pledge my support for your campaign, keep up the good work!"
    },
    {
      Name: "	Sand Owsnett	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Steve Sankey	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Topher Dawson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "I totally repudiate the idea that LGBT+ people can be reprogrammed back to 'normal' by Conversion Therapy and I think it’s cruel, wrong, and an attack on peoples’ human rights to try to do so."
    },
    {
      Name: "	Alison Johnstone	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I wholeheartedly support the campaign to end the inhumane and damaging practice of conversion therapy."
    },
    {
      Name: "	Lorna Slater	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I'm absolutely willing to pledge to support legislation bringing an end to Conversion Therapy in Scotland if elected to the Scottish Parliament."
    },
    {
      Name: "	Alex Staniforth	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Alys Mumford	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to support your campaign. Thank you for all of your work on it!"
    },
    {
      Name: "	Ben Parker	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support the campaign to introduce legislation to end Conversion Therapy."
    },
    {
      Name: "	Bill Wilson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Chas Booth	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Elaine Gunn	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Emily Frood	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Evelyn Weston	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Kate Nevens	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to pledge my support for the campaign to bring an end to conversion therapy in Scotland."
    },
    {
      Name: "	Steve Burgess	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "The Scottish Greens are commited to working to Ban so-called 'conversion therapy'. Seeking to change the sexual orientation or gender identity of LGBT+ people or alter a person’s sex characteristics without their consent is an unethical and unnecessary interventions. Our manifesto outlines our commitment to supporting lgbt+ people in more detail. The Scottish Greens have always taken a strong and proactive stance on LGBT+ equality, working for a progressive and inclusive Scotland where the LGBT+ community and intersex people can live and thrive freely."
    },
    {
      Name: "	Mags Hall	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.Cowdenbeath,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Amy Smith	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement:
        "I very much support this campaign, the pledge, and the legislative changes needed to bring about an end to conversion therapy."
    },
    {
      Name: "	Craig McCutcheon	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement: "It is past time to end CTS in Scotland."
    },
    {
      Name: "	Elspeth MacLachlan	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Fiona McOwan	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "I am proud to be a member of the Scottish Greens, who are committed to working to ban so-called 'conversion therapy'. Seeking to change the sexual orientation or gender identity of LGBT+ people or alter a person’s sex characteristics without their consent is an unethical and unnecessary interventions. Our manifesto outlines our commitment to supporting LGBT+ people in more detail. The Scottish Greens have always taken a strong and proactive stance on LGBT+ equality, working for a progressive and inclusive Scotland where the LGBT+ community and intersex people can live, love and thrive freely. ."
    },
    {
      Name: "	Jeroen van Leeuwen	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "In the 21st century, at a time when we have legalised equal marriage, it is a disgrace that such practices as 'conversion therapy' continue to exist for people who have often already suffered unacceptable persecution. Conversion therapy is an attack on the very principle of equality and goes hand in hand with misogyny and violent abuse. We must stamp it out once and for all. I support the pledge and hope that we can have cross-party agreement on this issue of human rights."
    },
    {
      Name: "	Marion Robertson	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Mark Ruskell	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "We in the Scottish Greens have long called for banning of conversion therapy in Scotland, conversion therapy is harmful, immoral and wrong.  We will continue to make that argument both in and out of the Scottish Parliament."
    },
    {
      Name: "	Paul Vallot	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Scott Rutherford	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "The Scottish Parliament must continue to push the envelope of equality and human rights at every opportunity. Banning conversion therapy is a significant step toward ending a historic and deeply bigoted attack on the LGBT+ community. As Scottish Parliament candidates it is incumbent on us to push this change even quicker. Thank you for all the great work the campaign is doing, let's get this done!"
    },
    {
      Name: "	Guy Ingerson	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.AberdeenCentral,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support this campaign to ban the abhorrent practice of conversion 'therapy'. As a member of the LGBT+ community I know how damaging this form of torture can be. Let's end this practice in Scotland now."
    },
    {
      Name: "	Bradley Booth	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "As a member of the LGBTQ+ community and with friends that have experienced this disgusting practice I give my full commitment to supporting the aims of ECT Scotland. In the, unlikely, event I am elected (being 7/7 on the list) I will support any piece of legislation that aims to outlaw this practice as soon as possible."
    },
    {
      Name: "	Leòdhas Iain Massie	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "It is essential that the 6th Parliament formally introduces a legal ban on conversion therapy in Scotland. It is mentally and physically abusive to those who are subjected to it and it is important to our wellbeing as LGBTQ+ people that no organisation or individual can practice conversion therapy in Scotland"
    },
    {
      Name: "	Maggie Chapman	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "As an intersectional feminist, I fully support the campaign to ban the abhorrent and inhuman practice of conversion 'therapy'. I will take every opportunity to oppose conversion 'therapy' whether elected or not. For me this is about fundamental rights: an individual should be able to express their gender identity and sexual orientation freely without the implied suggestion that they need to meet heteronormative demands to 'convert'."
    },
    {
      Name: "	Peter Kennedy	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rachel Shanks	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Sylvia Hardie	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: "I fully support legislation to end conversion therapy."
    },
    {
      Name: "	Barbra Harvie	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I wholeheartedly support the End Conversion Therapy Scotland campaign to stop this horrendous and unnecessary practice that has no place in an inclusive and accepting society."
    },
    {
      Name: "	Laura Moodie	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to whole-heartedly support the campaign to legislate to end conversion therapy in Scotland. All the best with your good work and let me know if there is anything further I can do to support your aims."
    },
    {
      Name: "	Dominic Ashmole	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "Real 'therapy' is surely a process founded in love, supporting insight, self-acceptance and growth. What an incongruous misuse of the term. I wholeheartedly support your campaign."
    },
    {
      Name: "	Charles Strang	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "I'm very happy to pledge to support legislation bringing an end to Conversion Therapy. It's a disgraceful practice which has no place in the modern Scotland."
    },
    {
      Name: "	Ciara Campbell	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "I fully agree with ending conversion therapy, and I pledge my support wholeheartedly."
    },
    {
      Name: "	James K Puchowski	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "Scotland is a country whose diversity is enriched by its LGBT+ population and its activism. As a younger candidate and a gay man, I have lived all of my adult life in Scotland and have many friends within the queer community who are an inspiration for how we will continue to develop as a just society. However, we need to go much more further, and make it clear that people's lives and experiences are not up for debate, they are not a religious or cultural challenge, and nor are people to be erased through traumatic 'conversion' attempts. We need systems and legislation in place - not gestures from our political representatives. I support the campaign to end conversion therapy in Scotland and pledge to work alongside politicians and civil society leaders to make sure this happens."
    },
    {
      Name: "	Kath Malone	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "As a queer person of faith this campaign hits particularly close to home. Conversion therapy denys the inherent worth and dignity of a person and should not be tolerated. I back this campaign whole-heartedly."
    },
    {
      Name: "	Peter Barlow	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "I wholeheartedly support your campaign to stamp out this practice. It is in no way a 'therapy' and has no place in a civilised inclusive society."
    },
    {
      Name: "	Tristan Gray	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement:
        "As one of the End Conversion Therapy Scotland petitioners I wholeheartedly support this campaign and will continue to do everything I can to ensure there is a thorough criminal ban on this practice, support for its victims, and community support to end institutional homophobia and transphobia in Scotland."
    },
    {
      Name: "	Scott Bevan	",
      Party: MSP.Party.ScottishGreenParty,
      Constituency: MSP.Constituency.Paisley,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is the assumption and assertion that there is something inherently 'wrong' with the minds and bodies of those who are not CIS gendered and heterosexual.  It is an abhorrent practice that amounts to nothing more than torture, (both physical and psychological), and is based upon proven nonsense and outright bigotry.  The Scottish Greens are 100% committed to ending once and for all this barbaric and ridiculous practice that has done irreversible harm to countless numbers of people.   There is no place for this assault on human rights in our country, or indeed anywhere in the world, and we will fight to ensure that it does not persist - overtly or covertly - in the future of our egalitarian and inclusive nation."
    },
    {
      Name: "	Carolynn Scrimgeour	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "Happy to confirm I pledge support for legislation to end conversion therapy and would support legislation brought forward to make this happen."
    },
    {
      Name: "	Charley O'Hear	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "I'd like to pledge my support to your campaign. If there's anything else I can do to help out please let me know."
    },
    {
      Name: "	Emma Sheppard	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "Thanks for giving me the opportunity to show my support for this. I cannot believe this campaign is still needed in 2021. The Scottish Greens are committed to working to Ban so-called “conversion therapy”. Seeking to change the sexual orientation or gender identity of LGBT+ people or alter a person’s sex characteristics without their consent is an unethical and unnecessary intervention. I know a lot of hard work has goine into this manifesto area. It outlines our commitment to supporting lgbt+ people in more detail. The Scottish Greens have always taken a strong and proactive stance on LGBT+ equality, working for a progressive and inclusive Scotland where the LGBT+ community and intersex people can live and thrive freely."
    },
    {
      Name: "	Erin Crawley	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "The Scottish Greens are committed to working to Ban so-called “conversion therapy”. Seeking to change the sexual orientation or gender identity of LGBT+ people or alter a person’s sex characteristics without their consent is an unethical and unnecessary intervention. Our manifesto outlines our commitment to supporting LGBT+ people in more detail. The Scottish Greens have always taken a strong and proactive stance on LGBT+ equality, working for a progressive and inclusive Scotland where the LGBT+ community and intersex people can live and thrive freely."
    },
    {
      Name: "	Ross Collins	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Ross Greer	",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "'Conversion therapy' is a benign way to describe abuse. I'm proud that the Scottish Greens manifesto includes an unequivocal commitment to ban it. If re-elected I would work to ensure we explore the quickest and most effective way to do this as soon as Parliament is reconvened."
    },
    {
      Name: "	Richard Leonard	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mark Griffin	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Michael McPake	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Monique McAdams	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EastKilbride,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Allyson Black	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.FalkirkEast,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Monica Lennon	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is a form of psychological abuse that can have a lasting impact on LGBT+ people and I wholly support the work the End Conversion Therapy campaign is doing to put an end to this abusive practice.  Your efforts to support LGBT+ young people and adults affected by conversion therapy is to be commended. I am fully behind your calls for a criminal ban in Scotland and I pledge to support any legislation to that effect.  I agree with you that, as well as the provision of conversion therapy, any ban must also include causing a person to undergo conversion therapy; removing a minor from Scotland to undergo conversion therapy abroad; and advertising an offer to provide conversion therapy. As you have outlined, I believe it is important that we engage with religious leaders on the impact of certain teachings on their LGBT members.  It is crucial that we also establish a public complaints mechanism for reports of conversion therapy and ensure there is support for survivors as well as awareness training for both the healthcare sector and religious organisations. One LGBT+ person being forced to undergo the trauma of conversion therapy is one too many.  I want to assure you of my unwavering support for your campaign and I pledge that I will be your friend and ally in putting an end to this shocking practice."
    },
    {
      Name: "	Chris Costello	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Eva Murray	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support in your campaign to see the abhorrent practice of conversion therapy banned in Scotland & as an MSP I would work with you to make this a reality."
    },
    {
      Name: "	Craig Carson	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowCathcart,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support ECT Scotland’s campaign to end conversation therapy. It has absolutely no place in Scotland and it’s unbelievable it hasn’t been banned before now. Those who have suffered this 'therapy' need proper support and we must stop it happening to others."
    },
    {
      Name: "	Pam Duncan-Glancy       	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support to ban conversion therapy in Scotland and whether I am elected in May or not, I will campaign with you to make that happen."
    },
    {
      Name: "	Pauline McNeill	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowShettleston,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Anas Sarwar	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support in your campaign. This is an important campaign and I am more than happy to support it."
    },
    {
      Name: "	James Kelly	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Sweeney	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I am fully committed to your vital campaign to end the barbaric practice of conversion therapy, and I will gladly work to ensure its abolition in law if elected."
    },
    {
      Name: "	William Docherty	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Willie Docherty	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: "I fully endorse and support your campaign."
    },
    {
      Name: "	Lewis Whyte	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.ArgyllAndBute,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "Conversation therapy is a disgusting practice that causes so much harm, and I fully back effective legislation that achieves the aims outlined in your request page."
    },
    {
      Name: "	Marion Donaldson	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rhoda Grant                    	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jo Kirby	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Moray,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Shaun Fraser	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Coilla Drake	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Orkney,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Erskine	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.SkyeLochaberAndBadenoch,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maddy Kirkman	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daniel Johnson	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Region: MSP.Region.Lothian,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I absolutely support a ban on conversion therapy and should I be re-elected in May I will be sure to work alongside colleagues to support this bill as it makes its way through Parliament in whatever way I can. "
    },
    {
      Name: "	Kirsteen Sullivan	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Linlithgow,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stephen Curran	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.MidlothianNorthAndMusselburgh,
      Region: MSP.Region.Lothian,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Foysol Choudhury	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Frederick Hessler	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "In what way can anyone call 'conversion' of this nature a 'therapy'?  A grade- A oxymoron if ever there was one. It's abuse, pure and simple, first and last. ( see - Milgrams 37) Solidarity."
    },
    {
      Name: "	Nick Ward	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "As a gay man myself, I find conversion therapy absolutely abhorrent. It is torture and absue and is clearly against anyone's human rights to have to go through such an experience. There is no place in modern Scotland for it."
    },
    {
      Name: "	Sarah Boyack	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Craig Miller	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.ClackmannanshireAndDunblane,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Rowley	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Cowdenbeath,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Julie MacDougall	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Dunfermline,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: "I offer my full support for this very important campaign."
    },
    {
      Name: "	Claire Baker	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Kirkcaldy,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ryan Smart	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "As someone who identifies as bisexual I am appalled that In 2021 this practice is still allowed in Scotland. I will ensure if elected to work with other MSP’s to end this horrendous practices."
    },
    {
      Name: "	Chris Kane	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Stirling,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ewan Dillon	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Barry Black	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AberdeenCentral,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: "none"
    },
    {
      Name: "	Heather Herbert	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AberdeenDonside,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support ECT Scotland’s campaign to end conversation therapy. It has absolutely no place in Scotland and it’s unbelievable it hasn’t been banned before now. Those who have suffered this 'therapy' need proper support and we must stop it happening to others."
    },
    {
      Name: "	Lynn Thomson	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Georgia Strachan	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Owen Wright	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.DundeeCityEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement:
        "I absolutely support the call to ban and put an end to the practice of conversion therapy, and pledge to support legislation to that aim if elected to become an MSP. These practices are inhumane and vile at their core, and have without a doubt have ruined many people's lives. It is appalling that conversion therapy has been allowed for so long in Scotland, and it must be banned in the next parliament."
    },
    {
      Name: "	Mercedes Villalba	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.DundeeCityWest,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I support the call to end the abusive practice of conversion therapy in Scotland and pledge to support legislation bringing an end to Conversion Therapy."
    },
    {
      Name: "	Kaamal Bola	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Marra	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard McCready	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Carol Mochan	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.CarrickCumnockAndDoonValley,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Claudia Beamish	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Clydesdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Colin Smyth	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Dumfriesshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin Whitfield	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EastLothian,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Ian Davidson	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kevin McGregor	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "I find it utterly appalling that there is not already a criminal ban on such an abhorrent practice in Scotland, the UK or indeed anywhere that claims to be a civil society. It is a real threat to the lives and welfare of LGBT+ people and I commit fully to support a ban and to vote for one in Parliament if elected. We also need to ensure that proper support for those who have been subjected to this  is fully available and readily accessible. Nothing short of a comprehensive ban in, implemented as soon as is practicable, can be seen as acceptable and I will stand and fight with those who have and could fall victim to this abuse."
    },
    {
      Name: "	Katherine Sangster	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Douglas McAllister	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Region: MSP.Region.WestScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Katy Clark	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.CunninghameNorth,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Jackie Baillie	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Dumbarton,
      Region: MSP.Region.WestScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Katie Pragnell	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Eastwood,
      Region: MSP.Region.WestScotland,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support to ban conversion therapy in Scotland and whether I am elected in May or not, I will campaign with you to end this practice. I support a bill in the Scottish Parliament that will establish a criminal ban on all promotion, provision, and coercion of others into undergoing LGBT+ conversion therapy. I support further measures to support victims of this abusive practice and community efforts to end the practice."
    },
    {
      Name: "	Francesca Brennan	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neil Bibby	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Paisley,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul O’Kane	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I support the campaign to end conversion therapy in Scotland. If elected I will support legislation in the Scottish Parliament to ban the appalling and harmful practice which has no place in Scotland and indeed the world. If not elected, I will continue to support in any way I can both through my role as a Councillor in East Renfrewshire and more generally as an LGBT+ party activist. Thank you for your work on this so far."
    },
    {
      Name: "	Ed Grady	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gurpreet Singh Johal	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Johanna Baxter	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Matt Kerr	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Nairn McDonald	",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Graeme Downie	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AberdeenshireEast,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew Brown	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AberdeenshireWest,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Craig Smith	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AlmondValley,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Cindy Douglas	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AngusNorthAndMearns,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graeme McKenzie	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.AngusSouth,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Esther Clark	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Ayr,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bill Cook	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghEastern,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Katrina Faccenda	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lezley Cameron	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Margaret Graham	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghWestern,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Monette Gordon	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.FalkirkWest,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Archie Dryburgh	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Keiran O’Neill	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      Support: MSP.Support.Yes,
      Statement:
        "I'd like to give my full and unqualified support to your campaign. As an LGBT+ man, this issue is a personal one and while the work of your campaign is inspiring, it should not have to exist in a so-called progressive country in 2021."
    },
    {
      Name: "	Zubir Ahmed	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Altany Craik	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martine Nolan	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Wendy Haynes	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.NorthEastFife,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Janine Rennie	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.PerthshireSouthAndKinrossShire,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin Kerr	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Shetland,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is a vile and abuse practice that does nothing but cause harm. I am shocked that it is still legal in a country like ours in the 21st century. I give my full backing to legislation that ends this practice, and I support the aims outlined on endconversiontherapy.scot"
    },
    {
      Name: "	Callum McNally	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Support: MSP.Support.Yes,
      Statement:
        "I wanted to write to you to confirm my unreserved support for your campaign. As a member of the LGBT+ community I am deeply grateful for the work you are doing, I am only sorry that it is still necessary in 2021. I wish you all the best and look forward to campaigning alongside you to end this damaging practice."
    },
    {
      Name: "	Frank McNally	",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.UddingstonAndBellshill,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul McGarry	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EastKilbride,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "I'd like to express my full support for ending conversion therapy in Scotland, which is long overdue. Conversion therapy causes a great deal of harm and I fully back legislation to stop it. It goes against my party's liberal values of equality."
    },
    {
      Name: "	Austin Reid	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.FalkirkWest,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mark McGeever	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "The notion that anyone's sexual orientation is something that can or should be changed is ridiculous and harmful. Conversion therapy is a contradiction in terms. Anyone offering such a service is endangering the health of those they seek to influence. It is an offensive and absurd practice that has no scientific basis, misleads anyone considering it, and exists only because of prejudice. It must end."
    },
    {
      Name: "	Ewan McRobert	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.CentralScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graham Watson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Karen Utting	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.CentralScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mark McGreever	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Joe McCauley	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowCathcart,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement:
        "I will do all I can to support ending Conversion Therapy in Scotland. I know just how important an issue this is as a member of the LGBT+ community."
    },
    {
      Name: "	David McKenzie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Region: MSP.Region.Glasgow,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "I have seen first hand the damaging effects conversion therapy can have, someone close to me was subjected to this after coming out as a teenager. It's brutal and should have no place in modern Scotland, the right to live your life openly as LGBT+ and the freedom to love who you choose are at the heart of Liberal values and I'm more than happy to back these proposals."
    },
    {
      Name: "	Andrew Chamberlain	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Speirs	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowPollok,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Nicholas Moohan	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowProvan,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Matthew Clark	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowShettleston,
      Region: MSP.Region.Glasgow,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Carole Ford	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Sheila Thomson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Rutherglen,
      Region: MSP.Region.Glasgow,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Alan Reid	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.ArgyllAndBute,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Molly Nolan	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I'd like to express my full support for ending conversion therapy in Scotland, which is long overdue. This is an important campaign and I am happy to support in any way I can."
    },
    {
      Name: "	David Gregg	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "I'd like to express my full support for banning conversion therapy in the next parliament. Thanks for running such an important campaign, and if there are any other ways support is required, please let me know"
    },
    {
      Name: "	Sheila Ritchie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Moray,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Neil Mitchison	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Denis Rixson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.SkyeLochaberAndBadenoch,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	William Sinclair	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Caron Lindsay	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AlmondValley,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Bruce Wilson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is an affront to the liberal democracy that we hold so dear. Being able to live without fear of such a crime is critically important to building a better society. I fully endorse this campaign. Thank you for all you are doing."
    },
    {
      Name: "	Jill Reilly	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghEastern,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Rebecca Bell	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "I'd be very happy to sign this petition. This is 100% in line with LibDem policy and I will share it with fellow candidates. I'm sure who will all want to sign it."
    },
    {
      Name: "	Fraser Graham	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "As Scottish Liberal Democrat candidate for Edinburgh Pentlands, and a bisexual man, I'll gladly sign your petition."
    },
    {
      Name: "	Fred Mackintosh	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I would be delighted to sign your election pledge to support legislation bringing an end to Conversion Therapy.  Conversion therapy causes a great deal of harm and I fully back legislation to stop it. It goes against my party's liberal values of equality."
    },
    {
      Name: "	Alex Cole-Hamilton	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghWestern,
      Region: MSP.Region.Lothian,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I am fully supportive of this- conversion therapy causes a great deal of harm and I fully back legislation to stop it. It goes against liberal values of equality."
    },
    {
      Name: "	Sally Pattle	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Linlithgow,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support your aims and am pleased to sign your pledge aiming to abolish the abhorrent practice of gay conversion therapy. That this practice still exists anywhere in the world appalls me and if elected in May, I'll help in any way I can to get this bill through parliament."
    },
    {
      Name: "	Charles Dundas	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.MidlothianNorthAndMusselburgh,
      Region: MSP.Region.Lothian,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Aude Boubaker-Calder	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Dunfermline,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "As an LGBTQIA+ Ally and future mum, I am wholeheartedly supporting your campaign. I am shocked and appalled to see that conversion therapy is still existing in the 21st century. It is time to act, ban once and for all this awful practice and condemn those who are still enforcing it. Let’s love be love and everyone be themselves!"
    },
    {
      Name: "	Jane Ann Liston	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Willie Rennie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.NorthEastFife,
      Region: MSP.Region.MidScotlandAndFife,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "If we are serious about wanting Scotland to be the best country in the world for LGBT+ people, then that means using the powers of the Scottish government to challenge hatred and discrimination, no matter where it comes from.  The End Conversion Therapy campaign have done valuable work keeping this on the political agenda by asking candidates in this May’s election to back their pledge. I would like to go one step further and commit that my party will back legislation for a ban on conversion therapy for LGBT+ people. This legislation would set in law sanctions for causing a person causing a person to undergo conversion therapy providing or advertising conversion therapy and importantly, removing a minor from Scotland to undergo conversion therapy abroad. I think that Scotland can be genuinely proud of the work that it has done to improve LGBT+ rights but there is plenty more to do. That’s why I’m proud to pledge my party’s support in ensuring this practice must be ended once and for all."
    },
    {
      Name: "	Peter Barrett	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Julia Brown	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.PerthshireSouthAndKinrossShire,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Fayzan Rehman	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Stirling,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Aisha Mir	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Isobel Davidson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenDonside,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement: "Everyone should be able to live the life they choose."
    },
    {
      Name: "	Conrad Wood	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenshireEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy should have no place in Scotland. I fully support stopping this abusive practice, and herby pledge to support legislation that brings it to an end."
    },
    {
      Name: "	Rosemary Bruce	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenshireWest,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Ian Yuill	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "There should be no place for conversion therapy in Scotland – or anywhere else."
    },
    {
      Name: "	John Waddell	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "Conversation therapy is a barbaric practice with no place in our society. It is nothing short of abuse based on prejudice and hatred and the next Parliament should consider it among it’s highest priorities to eradicate it from our society."
    },
    {
      Name: "	Ben Lawrie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AngusSouth,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "I believe that everyone should have the freedom to be their truest, most authentic self. I fully support ending the harmful and abusive practice of conversion therapy."
    },
    {
      Name: "	Alison Simpson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Crichton	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.DundeeCityEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Crichton	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.DundeeCityEast,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement:
        "Keep up the great work I genuinely couldnt believe that it is still a legal practise and we must end conversion therapy as soon as possible."
    },
    {
      Name: "	Kirsten Herbst-Gray	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CarrickCumnockAndDoonValley,
      Region: MSP.Region.SouthScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Amanda Kubie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Clydesdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Brodie	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Dumfriesshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "I have read your proposals and support the aims of your organisation in ending conversion therapy which is totally reprehensible and completely deprives individuals the right to be who they are."
    },
    {
      Name: "	Euan Davidson	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EastLothian,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "As the person who wrote and proposed the policy which made the Scottish Liberal Democrats the first party anywhere in the UK to back a ban of Conversion therapy I am delighted to sign this pledge. If I'm elected this will be a personal priority for me."
    },
    {
      Name: "	Jenny Marr	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement:
        "I support for the campaign and everything you are seeking to achieve. You will always have an ally in me and if there's anything I can do to help please let me know. "
    },
    {
      Name: "	AC May	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "Well done on your efforts to bring this abhorrent practice of conversion therapy to the forefront of public discussion."
    },
    {
      Name: "	Catriona Bhatia	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "Please add my name to the listing candidates who support your campaign to end conversion therapy in Scotland. It is shocking that this still goes on in 2021."
    },
    {
      Name: "	Katy Gordon	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to sign a pledge to work to bring an end to conversion therapy if I am elected as an MSP in the Scottish Parliament in May 2021. It is really shocking that conversion therapy should still exist as a treatment and the sooner the practice is ended the better. "
    },
    {
      Name: "	Ruby Kirkwood	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CunninghameNorth,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tom Armstrong	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CunninghameSouth,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jacci Stoyle	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Eileen McCartin	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Paisley,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ross Stalker	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.RenfrewshireNorthAndWest,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support in your efforts to ban the abhorrent practice of conversion 'therapy'. Aside from the social impact of an ideology that views LGBT+ people as inferior, the evidence clearly shows that efforts to change sexual orientation or gender identity are ineffective and damaging to mental health. Practitioners of conversion 'therapy' often claim that what they really promote is freedom of lifestyle choice. However, this is a complete red herring. A ban on conversion 'therapy' in no way restricts the lifestyle choices of LGBT+ people. If an LGBT+ person wishes to live a lifestyle that differs from the majority of the LGBT+ community, whether for religious reasons or any other reason, that is their choice, no matter how the rest of us may feel about their choices. Nobody is saying that we have the right to control how people respond to their own identity, however we do have the right to ban pseudoscience where there is clear evidence of harm. Promotion of a false choice does not increase freedom of choice. So-called reparative therapy for sexual orientation, particularly the version targeted at gay men, has thankfully been losing public credibility in recent years, though of course a full and comprehensive ban on the practice is still needed. But I am particularly concerned by the increasing publicity given to a narrative promoted by anti-transgender groups that gender incongruence can be a 'social contagion', despite the clear evidence that gender identity forms at an early age and that it is just as immutable as sexual orientation. As a cisgender member of the LGBT+ community I recognise that anti-trans rhetoric is often just a recycling of old homophobic tropes, and we must fight for a world in which transphobia is no less unacceptable than homophobia. None of us are free until all of us are free."
    },
    {
      Name: "	Susan Murray	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.Yes,
      Statement:
        "I support ending of the abusive practice of conversion therapy in Scotland using the devolved powers of the Scottish Government through legislation by the Scottish Parliament."
    },
    {
      Name: "	Katy Gordon	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rod Ackland	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	DesmondBouse	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AberdeenCentral,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	John Cole	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Support: MSP.Support.Yes,
      Statement:
        "This practice is outdated and has no support among professional therapists or mental health professionals in the NHS.  A number of politicians have spoken in favour of banning this practice, including the Prime Minister, but as yet nothing has passed into law. This is a matter for which responsibility has been devolved to the Scottish government so therefore it can be clearly stated that the Scottish Liberal Democrats support this legislation during our campaigning to become elected as MSP's in May. I will make it clear during my campaign for the Airdrie and Shotts seat that this is my view. It has already been banned in Brazil, Ecuador and Malta. In addition Canada, Chile, Germany, New Zealand and Mexico are also looking at outlawing this practice. As a Canadian by birth and I am happy to see Canada on this growing list of countries supporting this initiative."
    },
    {
      Name: "	Michael Turvey	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.AngusNorthAndMearns,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jamie Ross	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Ayr,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Iliyan Stefanov	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.ClackmannanshireAndDunblane,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mhairi Macdonald	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Malcolm Wood	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Cowdenbeath,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Elaine Ford	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.CumbernauldAndKilsyth,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andy Foxall	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Dumbarton,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Daniel Coleman	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.DundeeCityWest,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Tahir Jameel	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Eastwood,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Rolfe	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.FalkirkEast,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Iain McDonald 	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Mark Simons	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Support: MSP.Support.Yes,
      Statement:
        "The idea that LGBTQ+ people are in some way sick or abnormal and need to be cured or rectified is ludicrous and Conversion Therapy, which stems from this belief, is horrific. Conversion Therapy is a pseudoscientific medicalisation of the state of being of the LGBTQ+ community and is abhorrent. I whole-heartedly support ending and criminalising this violation of the fundamental human right of self determination, as well as giving whatever support is necessary to the victims of this inhumane practice."
    },
    {
      Name: "	Stephen McQuistin	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Support: MSP.Support.Yes,
      Statement:
        "Conversation therapy is barbaric: it simply has no place here in Scotland, or indeed anywhere else."
    },
    {
      Name: "	Alan Beal	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Kirkcaldy,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Martin Veart	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Liam McArthur	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Orkney,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "This is an important campaign and one that Scottish Liberal Democrats strongly support.  I wish you every success and look forward to helping play my part."
    },
    {
      Name: "	Christine Cosh	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Beatrice Wishart	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Shetland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "The Scottish Liberal Democrats rightfully voted to overwhelmingly oppose the practice of Conversion Therapy for LGBT+ youth at the party’s conference in 2014. I am more than willing to reaffirm our commitment to bring about an end to this harmful practice and offer my full support to this campaign."
    },
    {
      Name: "	Dawn Allan	",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.UddingstonAndBellshill,
      Support: MSP.Support.Yes,
      Statement:
        "This has my 100% support, although I am shocked that in 2021 it even exists."
    },
    {
      Name: "	Mark Meechan	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Katrina Angus	",
      Party: MSP.Party.ScottishLibertarianParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Harry Christian	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Calum Liptrot	",
      Party: MSP.Party.ScottishLibertarianParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tam Laird	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Cameron Paterson	",
      Party: MSP.Party.ScottishLibertarianParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Calum Paul	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.Kirkcaldy,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Morton	",
      Party: MSP.Party.ScottishLibertarianParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bryce Hope	",
      Party: MSP.Party.ScottishLibertarianParty,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stef Johnstone	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Region: MSP.Region.SouthScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jonathan Rainey	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.Dumbarton,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stephen Jamieson	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daniel Fraser	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.EdinburghWestern,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alan Findlay	",
      Party: MSP.Party.ScottishLibertarianParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neil Gray	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Region: MSP.Region.CentralScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I unequivocally support ending conversion therapy in Scotland and would support any legislation brought under Holyrood competency to do just that."
    },
    {
      Name: "	Fulton MacGregor	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I commit to supporting the end of conversion therapy. This practice is discriminatory and harmful to LGBT people and absolutely must be stopped."
    },
    {
      Name: "	Michelle Thomson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.FalkirkEast,
      Region: MSP.Region.CentralScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I would be happy to add my name to your register against conversion therapy."
    },
    {
      Name: "	Christina McKelvie	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      Region: MSP.Region.CentralScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I support this campaign in my capacity as an MSP and Candidate."
    },
    {
      Name: "	Stephanie Callaghan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.UddingstonAndBellshill,
      Region: MSP.Region.CentralScotland,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Cameron McManus	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Danish Ashraf	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Grant Ferguson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Josh Wilson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	lain Sinclair	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul Welsh	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bill Kidd	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Region: MSP.Region.Glasgow,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kaukab Stewart	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Region: MSP.Region.Glasgow,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to support your campaign to end conversion therapy in Scotland."
    },
    {
      Name: "	Ivan McKee	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowProvan,
      Region: MSP.Region.Glasgow,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Nicola Sturgeon	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Region: MSP.Region.Glasgow,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Clare Haughey	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Rutherglen,
      Region: MSP.Region.Glasgow,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Abdul Bostani	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Kerr	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Christina Cannon	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Katy Loudon	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Roza Salih	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Suzanne McLaughlin	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Maree Todd	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Fergus Ewing	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Leslie	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Orkney,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Tom Wills	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Shetland,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Kate Forbes	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.SkyeLochaberAndBadenoch,
      Region: MSP.Region.HighlandsAndIslands,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Emma Roddick	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Jamie Szymkowiak	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Ken Gowans	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mike MacKenzie	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Qasim Hanif	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rhiannon Spear	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Sarah Fanet	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is harmful, inhuman and a global ban has been backed by a UN report, religious leaders from around the world , health and counselling organisations. Scotland must ban this shameful practice, support its victims and raise awareness of its damaging impact. No faith, belief  or conscience can be used as an excuse to harm others. I pledge to support legislation bringing an end to Conversion Therapy."
    },
    {
      Name: "	Angus Robertson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "Best wishes to the campaign to end conversion therapy. I am pleased there is such strong cross-party support and hope it will lead to legislative change"
    },
    {
      Name: "	Ben Macpherson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support. There is absolutely no place for so-called conversion therapy in Scotland. Such practices are discriminatory, and harmful to the mental health and wellbeing of LGBTI people."
    },
    {
      Name: "	Catriona MacDonald	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Region: MSP.Region.Lothian,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement:
        "I support the pledge to end conversion therapy in Scotland and would like to add my name."
    },
    {
      Name: "	Sarah Masson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghWestern,
      Region: MSP.Region.Lothian,
      RegionRank: 6,
      Support: MSP.Support.Yes,
      Statement:
        "I would like to sign up to your pledge to end conversion therapy. Congratulations to everyone at the campaign team. This is such an important issue that I can promise to champion, if elected."
    },
    {
      Name: "	Fiona Hyslop	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Linlithgow,
      Region: MSP.Region.Lothian,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alex Orr	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alison Dickie	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrew Ewen	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graham Campbell	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Greg McCarra	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rob Connell	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.Lothian,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement:
        "This campaign important to me because on a personal basis, I suppose you could say that banning conversion therapy won’t affect me - I’m CIS-gendered, heterosexual, and really as a white, middle-aged, middle-class male, I’ve never been badly affected by prejudice in my life. But to put people through a process which pressures them, sometimes using their faith and personal relationships, to deny who they are, is a psychological torture that we should all stand against. If you only stand for principles when they affect you, you don’t you really have principles, just interests. It's important this election because I’ll be honest, my first reaction when asked about this issue was to be incredulous that these sorts of practices still exist. Then, I was horrified to find out that Scotland has several centres promoting these programmes, including at least one right here in Edinburgh. I’m also appalled by the lack of honesty in claims that asking for celibacy or similar denial is fundamentally different from conversion. To say, “we’re not telling you to not to BE gay or trans, we’re just telling you not to live your life as if you are gay or trans”, is to ask someone to live their life in denial of person they are. You can’t tell me that’s not only wrong, but dangerous and damaging. The practice should be banned outright."
    },
    {
      Name: "	Keith Brown	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ClackmannanshireAndDunblane,
      Region: MSP.Region.MidScotlandAndFife,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Annabelle Ewing	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Cowdenbeath,
      Region: MSP.Region.MidScotlandAndFife,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	David Torrance	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Kirkcaldy,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Swinney	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim Fairlie	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.PerthshireSouthAndKinrossShire,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Fiona Sarwar	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lee Robb	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 10,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Moraig Henderson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 9,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rosemary Hunter	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ross Cunningham	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Stefan Hoggan-Radu	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "Conversation therapy a disgusting breach of human rights. So I support your pledge It is an abhorrent practice and as a gay man, I am disgusted it is still ongoing."
    },
    {
      Name: "	Fergus Mutch	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenshireWest,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.YesWithCaveats,
      Statement:
        "There is no place for conversion therapy in Scotland. Such practices are abhorrent, discriminatory and deeply harmful to the health and wellbeing of LGBTI people.  Me and the SNP in general vehemently oppose conversion therapy. The UK government has made a commitment to ban such practices in their LGBT Action Plan, and I support that. However, much of what is required to take forward an effective ban is reserved to Westminster. If the UK government fails to take this important step then I will gladly pursue action to this effect within the limits of the current powers of the Scottish Parliament. You have my full support, and I wish you the very best with your campaign. I'm happy to be kept in the loop regarding any news or developments."
    },
    {
      Name: "	Christian Allard	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Fatima Joji	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Gillian Al-Samarai	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Cooke	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Joshua Mennie	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement:
        "I would like to give my full and unequivocal support for the campaign to end conversion therapy in Scotland. I am proud to have campaigned for LGBTQ+ rights & inclusion for years, therefore, delighted to sign up to the ECT pledge. Please let me know if there is anything I can do to further support the campaign."
    },
    {
      Name: "	Lynne Short	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 7,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Nadia El-Nakla	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	William Duff	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Siobhian Brown	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Ayr,
      Region: MSP.Region.SouthScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "There is no place for conversion therapy in Scotland. Such practices are discriminatory and harmful to the mental health and wellbeing of LGBTI people. "
    },
    {
      Name: "	Joan McAlpine	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Dumfriesshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Paul McLennan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EastLothian,
      Region: MSP.Region.SouthScotland,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Paul Wheelhouse	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Emma Harper	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ali Salamati	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Heather Anderson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Laura Brennan-Whitefield	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 12,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Mairi McAllan	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Walker	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Stacy Bradley	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 8,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Stephen Thompson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 11,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stuart McMillan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Rona Mackay	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.Yes,
      Statement: "I want to end conversion therapy. It is abhorrent."
    },
    {
      Name: "	Annette Ireland	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 7,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	CoIm Merrick	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daniel Kennedy	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 11,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Debra Torrance	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 8,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gavin Lundy	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 9,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Jonathan McColl	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 10,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kenneth Gibson	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.YesWithCaveats,
      Statement:
        "We in the SNP oppose LGBT conversion therapy practices, which are discriminatory and proven to have a harmful effect on the mental health and wellbeing of LGBT people. SNP MPs will press the UK government to reform laws and regulations, which are reserved to Westminster, to ban and prevent the practice of conversion therapy. It is important to recognise that such therapy is without any evidential basis, unsurprisingly, given that most of the research findings indicate an adverse impact on people’s mental health, rather than a ‘cure.’ The SNP Government does not support or advocate the practice of conversion therapy anywhere in the UK, and fully support moves to ban it. Some of my SNP MP colleagues participated in the recent Westminster Hall debate on the e-petition 300976 relating to LGBT conversion therapies, pressing the UK Government to ban the practice. My colleague and Women and Equalities Spokesperson Kirsten Oswald MP said during the debate: “Let us be clear: LGBT people do not need their identities debated nor do they need to be converted. That is fundamental. Nobody’s identity should be subject to debate or to change by other people.” I trust that you find this response reassuring that I and my SNP colleagues share your concerns about this abominable practice."
    },
    {
      Name: "	Lorna Douglas	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michelle Campbell	",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.Yes,
      Statement:
        "I fully support a ban of conversion therapy in our nation. Scotland must ensure the harm that has happened for so many in the past, stops for future generations. Those impacted by such practices must be supported and the LGBT+ community listened to for effective action in this ban."
    },
    {
      Name: "	Kevin Stewart	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenCentral,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: "I am happy to sign your pledge."
    },
    {
      Name: "	Jackie Dunbar	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenDonside,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I am happy to support your campaign to put an end to conversion therapy."
    },
    {
      Name: "	Gillian Martin	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenshireEast,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: "Happy to support your campaign to end conversion therapy."
    },
    {
      Name: "	Audrey Nicoll	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Angela Constance	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AlmondValley,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Mairi Gougeon	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AngusNorthAndMearns,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Graeme Dey	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AngusSouth,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jenni Minto	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ArgyllAndBute,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Karen Adam	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "I believe an end to conversation therapy is not only right but imperative. All the best, and may you be successful in your endeavours for the betterment of a welcoming, inclusive and overall kind Scotland."
    },
    {
      Name: "	Elena Whitham	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CarrickCumnockAndDoonValley,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "As a mum to an LGBTI+ young person I whole heartedly sign this pledge."
    },
    {
      Name: "	Marie McNair	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Màiri McAllan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Clydesdale,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Jamie Hepburn	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CumbernauldAndKilsyth,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Kenny Gibson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CunninghameNorth,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ruth Maguire	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CunninghameSouth,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Toni Giugliano	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Dumbarton,
      Support: MSP.Support.Yes,
      Statement: "none"
    },
    {
      Name: "	Shona Robison	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.DundeeCityEast,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Joe FitzPatrick	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.DundeeCityWest,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "It's a bit of a shame on us all that we're in this day and age, with so much reforms and yet this kind of behaviour is still legal in Scotland. I think potentially this is an area where the [Equalities and Human Rights] Committee might want to consider whether this is appropriate for a Committee Bill. Might not get to the point of being a priority for any government in th efuture, and if that's the case then I think the Committee should look as to whether we can use the particular powers we have in the Scottish Parliament to take forward Committee legislation to basically put it beyond doubt that this kind of behaviour is not acceptable in Scotland."
    },
    {
      Name: "	Shirley-Anne Somerville	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Dunfermline,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Collette Stevenson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EastKilbride,
      Elected: true,
      Support: MSP.Support.YesWithCaveats,
      Statement:
        "There is no place for conversion therapy in Scotland. Such practices are discriminatory and harmful to the mental health and wellbeing of LGBTI people. The UK Government made a commitment to ban conversion therapy in their LGBT Action Plan, and we fully support this, as much of what is needed to take forward an effective ban is reserved to Westminster. If the UK Government does not take forward such a ban, then we will do so within the limits of the Scottish Parliament’s powers. "
    },
    {
      Name: "	Colm Merrick	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Eastwood,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ash Denham	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghEastern,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gordon Macdonald	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Matheson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.FalkirkWest,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	James Dornan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowCathcart,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: "I support the moves to end conversion therapy"
    },
    {
      Name: "	Bob Doris	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "We should seek to legislate with existing powers. If they are insufficient that will become apparent. I was given reassurances from campaign that is not the case. Unequivocal support."
    },
    {
      Name: "	Humza Yousaf	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Mason	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowShettleston,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Willie Coffey	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Elected: true,
      Support: MSP.Support.YesWithCaveats,
      Statement:
        "I would be happy to pledge my support to a full and comprehensive ban on Conversion Therapy.  There is no place for Conversion Therapy in Scotland.  Such practices are discriminatory and harmful to the mental health and wellbeing of the LGBTI people. The UK Government made a commitment to ban Conversion Therapy in their LGBT Action Plan, and I fully support this.  Much of what is needed to take forward an effective ban is reserved to Westminster.  If the UK Government does not take forward such a ban, then the Scottish Government will do so within the limits of the Scottish Parliament’s powers."
    },
    {
      Name: "	Jenny Gilruth	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Colin Beattie	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidlothianNorthAndMusselburgh,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Christine Grahame	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Lochhead	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Moray,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Clare Adamson	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "There is no place for conversion therapy in Scotland. Such practices are discriminatory and harmful to the mental health and wellbeing of the LGBT+ community. I am proud to back the End Conversion Therapy campaign."
    },
    {
      Name: "	Alasdair Allan	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Elected: true,
      Support: MSP.Support.YesWithCaveats,
      Statement:
        "While I do not claim to know much about the subject of “conversion therapy”, I agree that the practices you describe are indeed dangerous and exploitative. People advertising that they can change people from being gay to heterosexual, are at the very least, guilty of misinformation and are likely to cause a great deal of human misery. While, as far as I know, there is no legislation being proposed around this issue, or even how such legislation might be framed. However, I will be aware of the valid points you make about this issue - which I am very sympathetic to - should it come before the next parliament. "
    },
    {
      Name: "	Rhuaraidh Fleming	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.NorthEastFife,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Adam	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Paisley,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Natalie Don	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.RenfrewshireNorthAndWest,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement:
        "There is absolutely no place for conversion therapy in Scotland and no-one should be subject to this. I am happy to support this campaign to help to put an end to this horrific practice."
    },
    {
      Name: "	Tom Arthur	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Elected: true,
      Support: MSP.Support.Yes,
      Statement: ""
    },
    {
      Name: "	Evelyn Tweed	",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Stirling,
      Elected: true,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Andrea Kozlowski	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ben Meechan	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Anna Freemantle-Zee	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Heather Astbury	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bruce Henderson	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Stefan Diesing	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Colin McFadyen	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.NorthEastScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Peter Morton	",
      Party: MSP.Party.ScottishRenew,
      Region: MSP.Region.WestScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Anna Nordahl	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Esthi Thurston	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ruth Wilkinson	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Suzanne Martin	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Renton	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Emma Watt	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lucy Hammond	",
      Party: MSP.Party.ScottishWomensEqualityParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alasdair Young	",
      Party: MSP.Party.SocialDemocraticParty,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lawrence Edwards	",
      Party: MSP.Party.SocialDemocraticParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neil Manson	",
      Party: MSP.Party.SocialDemocraticParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Brian Smith	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Maddie Jamieson	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Oisin Duncan	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sinead Daly	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Luke Ivory	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sean Robertson	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Yolanda Piotrowicz	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Kerr	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim Halfpenny	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lynda McEwan	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lucas Grant	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Constituency: MSP.Constituency.AberdeenDonside,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Wayne Scott	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Constituency: MSP.Constituency.DundeeCityEast,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Jim McFarlane	",
      Party: MSP.Party.TradeUnionistAndSocialistCoalition,
      Constituency: MSP.Constituency.DundeeCityWest,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Neil Wilson	",
      Party: MSP.Party.UkIndependenceParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Region: MSP.Region.CentralScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Cowan	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Steve Unwin	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Yvonne MacKay	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.CentralScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Daryl Gardner	",
      Party: MSP.Party.UkIndependenceParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Region: MSP.Region.Glasgow,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Amanda Ranaghan	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Chris Ho	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Hanna	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Glasgow,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Stephenson	",
      Party: MSP.Party.UkIndependenceParty,
      Constituency: MSP.Constituency.Moray,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Alan Breeze	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Bryan Foster	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Duncan Geddes	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Burger de Fremol	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Scorer	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.HighlandsAndIslands,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Donald MacKay	",
      Party: MSP.Party.UkIndependenceParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	John Mumford	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Lothian,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kenneth Lowry	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Lothian,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Steve Hollis	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.Lothian,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Douglas Watters	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Edward McNally	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	George Cormack	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Lynda Davis	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.MidScotlandAndFife,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Albert Emery	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Mackay	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Duncan Odgers	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Gerald Haddrell	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Kathleen Rowham	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	William Morren	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.NorthEastScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	David Blaymires	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 6,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Julia Searle	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Nick Hollis	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Patricia Bryant	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Patricia Mountain	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Richard Elvin	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.SouthScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Janice MacKay	",
      Party: MSP.Party.UkIndependenceParty,
      Constituency: MSP.Constituency.Eastwood,
      Region: MSP.Region.WestScotland,
      RegionRank: 1,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Eunice Normansell	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 2,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Ian Emery	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 5,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Robert Hill	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 3,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Sharon Boyle	",
      Party: MSP.Party.UkIndependenceParty,
      Region: MSP.Region.WestScotland,
      RegionRank: 4,
      Support: MSP.Support.NoReply,
      Statement: "none"
    },
    {
      Name: "	Michael Banks	",
      Party: MSP.Party.VanguardParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,
      Support: MSP.Support.NoReply,
      Statement: "none"
    }
  ];
}
