import React from "react";
import "../App.css";
import { Constituency, MSP, Party, Support } from "../DTOs/Types";
import { MSPs } from "../Data/MSPs";
import Select, { ActionMeta, ValueType } from "react-select";

interface IConstituencyMap {
  constituency: string;
  msps: IMSPEmails[];
}

interface IMSPEmails {
  msp: string;
  party: string;
  email: string;
  haveBeenContacted: boolean;
}

interface IConstituencies {
  value: Constituency;
  label: string;
}

class EmailPage extends React.Component<{}, IConstituencyMap> {
  public render(): JSX.Element {
    const constituencies = new Array<IConstituencies>();
    let i = 1;
    while (i < 72) {
      constituencies.push({
        value: i,
        label: this.CamelCaseToSentence(Constituency[i])
      });
      i++;
    }

    return (
      <div className="App email-page">
        <div style={{ marginBottom: "50px" }}>
          <h1>Email Your MSP</h1>
          <p>
            Email your MSP and ask them to pledge their support for our call to
            ban Conversion Therapy. You can find their emails below and a
            suggested template, though writing an email in your own words will
            give a stronger impression to MSPs.
          </p>
        </div>
        <div className="email-table">
          <h3>Find Your MSPs</h3>
          <Select
            name={"Select your constituency"}
            options={constituencies}
            onChange={this.ConstituencyMap}
          />
        </div>
        <div>
          {this.state?.constituency}
          <ul>
            {this.state?.msps.map(m => (
              <li>
                <p>
                  {m.msp}
                  {m.party}
                </p>
                <p>
                  Have they been emailed by a constituent already:{"  "}
                  {this.FormatContacted(m.haveBeenContacted)}
                </p>
                <a
                  href={
                    "mailto:" +
                    m.email +
                    "?subject=End Conversion Therapy&cc=endconversiontherapyscotland@gmail.com&body=" +
                    this.MailBody(this.FormatName(m.msp)) +
                    ",%0D%0A"
                  }
                >
                  {m.email}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <h3>Suggested Email Template</h3>
        <div>
          <p>Dear [Insert Name] MSP,</p>
          <p>
            Last year a petition was submitted to the Scottish Parliament
            calling for a criminal ban on Conversion Therapy in Scotland. The
            petition has been accepted and was considered by the Equalities and
            Human Rights Committee.
          </p>
          <p>
            The committee has requested that this issue should be fully acted on
            after the election. There was support for a committee bill to be
            developed should the Government not consider it a priority, and
            members of the committee underlined the importance of showing that
            this practice cannot continue and must be brought to an end.
          </p>
          <p>
            Despite so much progress around LGBT+ rights, there is a real threat
            to LGBT+ people throughout their lives from conversion therapy.
            Conversion therapy, the forced conditioning against a person’s
            sexuality or gender identity, has damaged generations of LGBT+ young
            people and adults and continues to do so. This incredibly harmful
            practice especially targets LGBT+ people when they are at their most
            vulnerable. You can find more information here:
            https://endconversiontherapy.scot/background.
          </p>
          <p>
            I hope that in the next Parliamentary term you will have the
            opportunity to vote for a ban on Conversion Therapy which ends its
            use in our country and supports those who have already fallen victim
            to this abusive practice. I am writing to you to ask you to pledge
            support for a full and comprehensive ban on Conversion Therapy (as
            set out here: https://endconversiontherapy.scot/request) through the
            Scottish Parliament.
          </p>
          <p>
            Please send your response to the petition team at
            endconversiontherapyscotland@gmail.com so that they can publish it
            on their site for your constituents.
          </p>
          <p>
            [Consider adding your personal story and reasons for supporting the
            ban.]
          </p>
          <p>
            Kind regards, <br />
            [Your Name]
          </p>
        </div>
      </div>
    );
  }

  private MailBody(name: string): string {
    return "Dear " + name + " MSP";
  }

  private FormatName(passed: string) {
    let result = passed;
    if (passed.includes(",")) {
      const names = passed.split(",");
      result = names[1] + " " + names[0];
    }
    return result;
  }

  private IncumbentEmail(msp: MSP): string {
    const splitName = msp.Name.split(",");
    const name = splitName[1].toLowerCase() + "." + splitName[0].toLowerCase();
    return name + ".msp@parliament.scot";
  }

  private readonly ConstituencyMap = (
    selected: ValueType<IConstituencies, false>,
    action: ActionMeta<IConstituencies>
  ): void => {
    if (selected) {
      const result = new Array<IMSPEmails>();
      const msps = this.GetConstituencyMSPs(selected.value);
      msps.forEach(m =>
        result.push({
          email: this.IncumbentEmail(m),
          msp: this.FormatName(m.Name),
          party: " - " + this.CamelCaseToSentence(Party[m.Party]),
          haveBeenContacted: m.HaveBeenContacted ? m.HaveBeenContacted : false
        })
      );
      this.setState({ constituency: selected.label, msps: result });
    }
  };

  private GetConstituencyMSPs(constituency: Constituency): MSP[] {
    const region = MSPs.MSPsCollection.find(
      m => m.Constituency === constituency
    )?.Region;
    const constituencyMSP = MSPs.MSPsCollection.find(
      m =>
        m.Constituency === constituency &&
        m.Support === Support.NoReply &&
        !m.StandingDown
    );
    const regionalMsps = MSPs.MSPsCollection.filter(
      m =>
        m.Region === region &&
        !m.StandingDown &&
        m.Constituency == null &&
        m.Support === Support.NoReply
    );
    if (constituencyMSP) {
      regionalMsps.push(constituencyMSP);
    }
    return regionalMsps;
  }

  private CamelCaseToSentence(passed: string) {
    const result = passed.replace(/([A-Z])/g, " $1");
    let final = result.charAt(0).toUpperCase() + result.slice(1);
    final = final.replace("And", "and");
    return final;
  }

  private FormatContacted(value: boolean): JSX.Element {
    switch (value) {
      case true:
        return (
          <b style={{ margin: 0, color: "#06a800", display: "inline-block" }}>
            {"Yes"}
          </b>
        );
      case false:
        return (
          <p style={{ margin: 0, color: "#333333", display: "inline-block" }}>
            {"No"}
          </p>
        );
    }
  }
}

export default EmailPage;
