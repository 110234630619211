import React from "react";
import "../App.css";

class PetitionConsultationPage extends React.Component<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className="App">
        <h1>Petition Consultation</h1>
        <p>
          On Tuesday 6th July the Scottish Parliament’s Equalities, Human Rights
          and Civil Justice Committee launched a consultation on our{" "}
          <a href="/consultation">petition</a>.
        </p>
        <p>
          You can find the consulation here and we encourage all LGBT+ and
          allies to make their voices heard to secure a swift, thorough, and
          inclusive ban on this abusive practice:{" "}
          <a
            href={
              "https://yourviews.parliament.scot/ehrc/petition-end-conversion-therapy-views/"
            }
          >
            consultation
          </a>
        </p>
        <p>
          We have suggestions on what you may wish to consider in your response:
        </p>
        <br />
        <h3>
          Section 1 - What are your views on the action called for in the
          petition?
        </h3>
        <ul>
          <li>
            A comprehensive ban on conversion therapy, accompanied by support to
            survivors and communities impacted by these practices is both
            necessary and urgent.
          </li>
          <li>
            The ban must be comprehensive. It must include all forms of
            Conversion Therapy on the basis of sexuality or gender identity
            without exception.
          </li>
          <li>
            The Scottish Government should act immediately on Conversion
            Therapy, without waiting for Westminster, to prevent further harm.
          </li>
        </ul>
        <h3>
          Section 2 - What action would you like to see the Scottish Government
          take?
        </h3>
        <ul>
          <li>
            The implementation of a criminal ban on the promotion, provision,
            causing of a person to undergo conversion therapy or removing a
            person from the UK to undergo conversion therapy abroad.
          </li>
          <li>
            Training on safeguarding and awareness in the public health service
            and private healthcare providers, and the establishment of an
            anonymous reporting system.
          </li>
          <li>
            Outreach and engagement with religious and community leaders
            including training to explain the impact of certain teachings on
            LGBT+ members of their communities.
          </li>
          <li>
            Outreach and support for survivors and communities affected by
            conversion therapy.
          </li>
        </ul>
        <h3>
          Section 3 - Do you have suggestions on how the Committee can take
          forward its consideration of the petition (e.g. who should it talk to
          and hear from)?
        </h3>
        <ul>
          <li>
            Survivors and those who have come to harm through Conversion Therapy
            should be at the forefront of any decision making.
          </li>
          <li>
            LGBT+ rights groups and mental health experts should also be invited
            to provide evidence and inform the committee on the best approach to
            supporting survivors.
          </li>
          <li>
            Legal expertise from those with experience in implementing Scottish
            law in similar areas, for example in domestic abuse, hate crime, and
            coercive control legislation should be considered to examine the
            possible application and impact of a criminal ban.
          </li>
        </ul>
      </div>
    );
  }
}

export default PetitionConsultationPage;
