import React from "react";
import "../App.css";

class RequestPage extends React.Component<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className="App request">
        <p>
          This following definition sets out measures we are calling on Holyrood
          candidates to support that would establish criminal offences and
          support for victims of conversion therapy.
        </p>
        <h2>What is Conversion Therapy?</h2>
        Conversion therapy aims to change or suppress an individual’s sexual
        orientation, to repress or reduce their sexual attraction or behaviours,
        or to change an individual’s gender identity to match the sex they were
        assigned at birth.
        <br /> <br />
        Conversion therapy, the forced conditioning against a person’s sexuality
        or gender identity, has damaged generations of LGBT+ young people and
        adults and continues to do so. Almost two-thirds of respondants to a
        survey on the practice had suffered from mental health issues as a
        result of experiencing conversion therapy, and nearly a third said they
        had sought counselling to help them recover from it. Mental health
        issues caused by experiences of Conversion Therapy include many
        experiencing self-harm, eating disorders, and attempted suicide.{" "}
        <a href={"https://www.surveymonkey.com/stories/SM-8PKJ3PM8/"}>
          Source: Ozanne Foundation
        </a>
        <br /> <br />A majority of those who have experienced the practice
        support{" "}
        <a href={"https://www.surveymonkey.com/stories/SM-5F8L68X8/"}>
          a criminal ban
        </a>
        .
        <br /> <br />
        Our call for a ban is backed by a UN independent expert{" "}
        <a
          href={
            "https://www.ohchr.org/en/NewsEvents/Pages/DisplayNews.aspx?NewsID=26051&LangID=E"
          }
        >
          report
        </a>{" "}
        to the Human Rights Council,{" "}
        <a href={"https://globalinterfaith.lgbt/"}>
          religious leaders from across the world
        </a>
        , and{" "}
        <a
          href={
            "https://www.bacp.co.uk/media/6526/memorandum-of-understanding-v2-reva-jul19.pdf"
          }
        >
          Healthcare and Counselling organisations
        </a>{" "}
        from across the UK.
        <h2>What are we calling for?</h2>
        We are calling for action by the Scottish Government to bring an end to
        the abusive practice of conversion therapy in Scotland. The experiences
        of those who were put through this practice show a significant public
        health case for banning conversion therapy and the need for a criminal
        ban. These areas fall within the devolved powers of the Scottish
        Government and should be legislated for by the Scottish Parliament.{" "}
        <br /> <br />
        Legislation should be introduced to ban: <br />
        <br />
        <ul style={{ width: "70%", margin: "auto", textAlign: "start" }}>
          <li>causing a person to undergo conversion therapy</li>
          <li>
            removing a minor from Scotland to undergo conversion therapy abroad
          </li>
          <li>providing conversion therapy</li>
          <li>advertising an offer to provide conversion therapy</li>
        </ul>{" "}
        <br />
        This must be supplemented by further measures, including: <br />
        <br />
        <ul style={{ width: "70%", margin: "auto", textAlign: "start" }}>
          <li>
            establishing a public complaints mechanism for reports of conversion
            therapy
          </li>
          <li>
            ensuring support for survivors, including access to counselling
          </li>
          <li>
            safeguarding and awareness training for public and private
            healthcare and support sector
          </li>
          <li>
            safeguarding and awareness training for religious organisations
          </li>
          <li>
            engagement with religious leaders on the impact of certain teachings
            on their LGBT members
          </li>
        </ul>
        <h2>Request</h2>
        <p>
          We ask that all candidates for the Scottish Parliament in the 2021
          election pledge to support legislation bringing an end to Conversion
          Therapy.
        </p>
        <p>
          <b>
            Are you an MSP or candidate who wants to make sure your views are
            represented here?
          </b>
        </p>
        <p>
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            Email us
          </a>{" "}
          to let us know you back this request and we'll make sure your response
          is listed on this site.
        </p>
        <p>
          Our email address is{" "}
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            endconversiontherapyscotland@gmail.com
          </a>
          .
        </p>
      </div>
    );
  }
}

export default RequestPage;
