import React from "react";
import CandidateStatement from "../Media/CandidateStatement.jpg";
import "../App.css";

class MediaPage extends React.Component<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className="App main-table">
        <p>
          <b>
            Are you an MSP or candidate who wants to make sure your views are
            represented here?
          </b>
        </p>
        <p>
          Read our <a href={"/request"}>request</a> and{" "}
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            email us
          </a>{" "}
          and we'll make sure your response is listed here.
        </p>
        <p>
          Our email address is{" "}
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            endconversiontherapyscotland@gmail.com
          </a>
          .
        </p>
        <h3>Draft Statement</h3>
        <p>As a candidate in the 2021 Scottish Parliamentary elections:</p>
        <p>
          I have pledged to support the campaign to bring an end to conversion
          therapy in Scotland.
        </p>
        <p>
          I will support a bill in Scottish Parliament that will establish a
          criminal ban on all promotion, provision, and coercion of others into
          undergoing LGBT+ conversion therapy.
        </p>
        <p>
          I will support further measures to support victims of this abusive
          practice and community efforts to end the practice.
        </p>
        <p>
          I support the definiton for conversion therapy and measures requested
          at https://endconversiontherapy.scot/request.
        </p>
        <img
          style={{ width: "60%", margin: "20px", minWidth: "500px" }}
          src={CandidateStatement}
          alt={"Candidate statement"}
        />
      </div>
    );
  }
}

export default MediaPage;
