import React from "react";
import * as MSPs from "../Data/MSPs";
import * as MSP from "../DTOs/Types";
import "../App.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  numberFilter,
  textFilter
} from "react-bootstrap-table2-filter";
import * as Candidates from "../Data/Candidates";
import { ConstituencyToRegionMap } from "../Data/ConstituencyToRegionMap";
import Tories from "../Media/Tories.jpg";
import Greens from "../Media/Greens.png";
import Labour from "../Media/Labour.png";
import LibDems from "../Media/LibDems.png";
import SNP from "../Media/SNP.svg";
import ReformUK from "../Media/ReformUK.png";
import All4Unity from "../Media/AllForUnity.png";
import FamilyParty from "../Media/FamilyParty.jpg";
import UKIP from "../Media/UKIP.png";
import AlbaParty from "../Media/AlbaParty.jpg";
import Abolish from "../Media/Abolish.jpg";
import Communists from "../Media/Communists.png";
import Freedom from "../Media/FreedomParty.jpg";
import IGV from "../Media/IGV.png";
import Liberal from "../Media/Liberal.png";
import Libertarians from "../Media/Libertarians.jpg";
import Reclaim from "../Media/Reclaim.jpeg";
import Renew from "../Media/Renew.png";
import Scotia from "../Media/Scotia.png";
import SDP from "../Media/SDP.png";
import TUSC from "../Media/TUSC.png";
import WEP from "../Media/WEP.jpg";
import { default as Select } from "react-select";
import { ActionMeta, ValueType } from "react-select";

interface IConstituencyMap {
  constituency: MSP.Constituency;
  msps: IMSPSupport[];
}

interface IMSPSupport {
  name: JSX.Element;
  party: JSX.Element;
  support: MSP.Support;
  constituency?: MSP.Constituency;
  statement: string;
  regionRank?: number;
}

interface IConstituencies {
  value: MSP.Constituency;
  label: string;
}

interface ITablePageState {
  SectionSelected: SelectedSection;
  Constituency?: IConstituencyMap;
}

interface CandidateInfo {
  name: JSX.Element;
  party: JSX.Element;
  haveBeenContacted: boolean | undefined;
  support: MSP.Support;
  region: string;
  regionRank: string | number | undefined;
  constituency: string | null;
  statement: string;
}

interface IncumbentInfo {
  name: JSX.Element;
  party: JSX.Element | undefined;
  haveBeenContacted: boolean | undefined;
  support: MSP.Support;
  region: string;
  constituency: string | null;
  statement: string;
}

enum SelectedSection {
  Neither,
  AllCandidates,
  Incumbents
}

class TablePage extends React.Component<{}, ITablePageState> {
  public constructor(props: {}) {
    super(props);

    this.state = {
      SectionSelected: SelectedSection.Neither,
      Constituency: undefined
    };
  }

  public render(): JSX.Element {
    const constituencies = new Array<IConstituencies>();
    let i = 1;
    while (i < 72) {
      constituencies.push({
        value: i,
        label: this.CamelCaseToSentence(MSP.Constituency[i])
      });
      i++;
    }

    const data = MSPs.MSPs.MSPsCollection.filter(m => !m.StandingDown);
    let dataMap = data.map(d => ({
      name: this.FormatName(d.Name, true, d.Support),
      party: this.FormatParty(d.Party),
      haveBeenContacted: d.HaveBeenContacted,
      support: d.Support,
      region: this.FormatRegion(d.Constituency, d.Region),
      constituency: d.Constituency
        ? this.CamelCaseToSentence(MSP.Constituency[d.Constituency])
        : null,
      statement: d.Statement,
      regionRank: d.Constituency == null ? "" : d.RegionRank
    }));

    this.SortIncumbents(dataMap);

    const data2 = MSPs.MSPs.MSPsCollection.filter(m => m.StandingDown);
    const dataMap2 = data2.map(d => ({
      name: this.FormatName(d.Name, true, d.Support),
      party: this.FormatParty(d.Party),
      region: this.FormatRegion(d.Constituency, d.Region),
      constituency: d.Constituency
        ? this.CamelCaseToSentence(MSP.Constituency[d.Constituency])
        : null
    }));

    const data3 = Candidates.MSPs.MSPsCollection.filter(m => !m.StandingDown);
    const dataMap3 = data3
      .map(d => ({
        name: this.FormatName(d.Name, false, d.Support),
        party: this.FormatParty(d.Party),
        haveBeenContacted: d.HaveBeenContacted,
        support: d.Support,
        region: this.FormatRegion(d.Constituency, d.Region),
        constituency: d.Constituency
          ? this.CamelCaseToSentence(MSP.Constituency[d.Constituency])
          : null,
        statement: d.Statement,
        regionRank: d.Constituency ? "" : d.RegionRank
      }))
      .concat(dataMap);

    const dataMapCandidates = new Array<CandidateInfo>();

    dataMap3.forEach(d => {
      const candidate = dataMapCandidates.find(c => c.name.key === d.name.key);
      if (candidate) {
        candidate.name = <b>{candidate.name}</b>;
        candidate.constituency = candidate.constituency
          ? candidate.constituency
          : d.constituency;
        candidate.regionRank = candidate.regionRank
          ? candidate.regionRank
          : d.regionRank;
      } else {
        dataMapCandidates.push(d);
      }
    });

    const sortByKey = (
      a: JSX.Element,
      b: JSX.Element,
      order: string,
      dataField: string
    ) => {
      if (order === "asc" && a.key != null && b.key != null) {
        return b.key > a.key ? -1 : 1;
      } else if (a.key != null && b.key != null) {
        return a.key < b.key ? -1 : 1; // desc
      }
      return 1;
    };

    this.SortCandidates(dataMapCandidates);

    const columns = [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" }),
        sortFunc: sortByKey
      },
      {
        dataField: "party",
        text: "Party",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" }),
        sortFunc: sortByKey
      },
      {
        dataField: "haveBeenContacted",
        text: "Have Been Contacted",
        sort: true,
        formatter: (value: boolean): JSX.Element => {
          return this.FormatContacted(value);
        }
      },
      {
        dataField: "support",
        text: "Support",
        sort: true,
        formatter: (value: MSP.Support): JSX.Element => {
          return this.FormatSupport(value);
        }
      },
      {
        dataField: "region",
        text: "Region",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" })
      },
      {
        dataField: "constituency",
        text: "Constituency",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" })
      },
      { dataField: "statement", text: "Statement" }
    ];

    const columns2 = [
      {
        dataField: "name",
        text: "Name"
      },
      {
        dataField: "party",
        text: "Party"
      },
      {
        dataField: "region",
        text: "Region",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" })
      },
      {
        dataField: "constituency",
        text: "Constituency",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" })
      }
    ];

    const columns3 = [
      {
        dataField: "name",
        text: "Name",
        sortFunc: sortByKey
      },
      {
        dataField: "party",
        text: "Party",
        headerStyle: () => {
          return { width: "250px" };
        },
        sortFunc: sortByKey
      },
      {
        dataField: "support",
        text: "Support",
        sort: true,
        formatter: (value: MSP.Support): JSX.Element => {
          return this.FormatSupport(value);
        }
      },
      {
        dataField: "region",
        text: "Region",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" })
      },
      {
        dataField: "regionRank",
        text: "Region Rank",
        sort: true,
        filter: numberFilter({ placeholder: "Enter number" })
      },
      {
        dataField: "constituency",
        text: "Constituency",
        sort: true,
        filter: textFilter({ placeholder: "Enter search" }),
        headerStyle: () => {
          return { width: "300px" };
        }
      },
      { dataField: "statement", text: "Statement" }
    ];

    const showHeader = this.state.Constituency ? "block" : "none";

    return (
      <div className="App main-table">
        <div>
          <p className="party-pledge-header">PARTY PLEDGES</p>
          <div className="party-pledges">
            <div className="labour-pledge">
              <img src={Labour} alt="labour logo" />
              <p>Scottish Labour Party</p>
            </div>
            <div className="green-pledge pledged">
              <img src={Greens} alt="labour logo" />
              <p>Scottish Green Party</p>
            </div>
            <div className="libdem-pledge pledged">
              <img src={LibDems} alt="labour logo" />
              <p>Scottish Liberal Democrats</p>
            </div>
            <div className="snp-pledge">
              <img src={SNP} alt="labour logo" />
              <p>Scottish National Party</p>
            </div>
            <div className="tory-pledge">
              <img src={Tories} alt="labour logo" />
              <p>Scottish Conservative Party</p>
            </div>
          </div>
          <p style={{ fontSize: "0.8em", marginBottom: "30px" }}>
            Highlighted parties have pledged to back our requests as part of
            their parliamentary campaign
          </p>
        </div>
        <div className="candidate-table">
          <h3>Select your Constituency</h3>
          <Select
            name={"Select your constituency"}
            options={constituencies}
            onChange={this.ConstituencyMap}
          />
        </div>
        <div className="constituency-list">
          <div className="headers" style={{ display: showHeader }}>
            <div className="cand-name">Name</div>
            <div className="cand-party">Party</div>
            <div className="cand-con">Type</div>
            <div className="cand-rr">List Rank</div>
            <div className="cand-support">Support</div>
            <div className="cand-stat">Statement</div>
          </div>
          <ul>
            {this.state?.Constituency?.msps.map(m => (
              <li>
                <ul className="candidates">
                  <li className="cand-name">{m.name}</li>
                  <li className="cand-party">{m.party}</li>
                  <li className="cand-con">
                    {m.constituency === this.state.Constituency?.constituency
                      ? "Constituency Candidate"
                      : "List Candidate"}
                  </li>
                  <li className="cand-rr">{m.regionRank}</li>
                  <li className="cand-support">
                    {this.FormatSupport(m.support)}
                  </li>
                  <li className="cand-stat">{m.statement}</li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div className="pledge-totals">
          <div style={{ display: "inline-block" }}>
            <p>
              <b>Support across current parliamentary parties -</b>
            </p>
            <p>
              <b>Total pledges:</b>
            </p>
            <p>Scottish Labour pledges:</p>
            <p>Scottish Green pledges:</p>
            <p>Scottish LibDems pledges:</p>
            <p>SNP pledges:</p>
            <p>Scottish Tory pledges:</p>
            <p>-</p>
            <p>Other Party pledges:</p>
          </div>
          <div
            style={{
              display: "inline-block",
              marginLeft: "30px",
              textAlign: "right",
              fontWeight: "bold"
            }}
          >
            <p>
              {
                dataMapCandidates.filter(d => d.support === MSP.Support.Yes)
                  .length
              }
            </p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishLabourParty]
                ).length
              }
            </p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishGreenParty]
                ).length
              }
            </p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key ===
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats]
                ).length
              }
            </p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishNationalParty]
                ).length
              }
            </p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key ===
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length
              }
            </p>
            <p>-</p>
            <p>
              {
                dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key !== MSP.Party[MSP.Party.ScottishLabourParty] &&
                    d.party.key !== MSP.Party[MSP.Party.ScottishGreenParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishNationalParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length
              }
            </p>
          </div>
          <div
            style={{
              display: "inline-block",
              textAlign: "left",
              fontWeight: "bold"
            }}
          >
            <p>
              {"/"}
              {dataMapCandidates.length}
            </p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d => d.party.key === MSP.Party[MSP.Party.ScottishLabourParty]
                ).length
              }
            </p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d => d.party.key === MSP.Party[MSP.Party.ScottishGreenParty]
                ).length
              }
            </p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d =>
                    d.party.key ===
                    MSP.Party[MSP.Party.ScottishLiberalDemocrats]
                ).length
              }
            </p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d =>
                    d.party.key === MSP.Party[MSP.Party.ScottishNationalParty]
                ).length
              }
            </p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d =>
                    d.party.key ===
                    MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length
              }
            </p>
            <p>-</p>
            <p>
              {"/"}
              {
                dataMapCandidates.filter(
                  d =>
                    d.party.key !== MSP.Party[MSP.Party.ScottishLabourParty] &&
                    d.party.key !== MSP.Party[MSP.Party.ScottishGreenParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishNationalParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length
              }
            </p>
          </div>
          <div
            style={{
              display: "inline-block",
              textAlign: "left",
              fontWeight: "bold",
              marginLeft: "10px"
            }}
          >
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(d => d.support === MSP.Support.Yes)
                  .length /
                  dataMapCandidates.length) *
                  100
              )}
              {"%"}
            </p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishLabourParty]
                ).length /
                  dataMapCandidates.filter(
                    d =>
                      d.party.key === MSP.Party[MSP.Party.ScottishLabourParty]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishGreenParty]
                ).length /
                  dataMapCandidates.filter(
                    d => d.party.key === MSP.Party[MSP.Party.ScottishGreenParty]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key ===
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats]
                ).length /
                  dataMapCandidates.filter(
                    d =>
                      d.party.key ===
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key === MSP.Party[MSP.Party.ScottishNationalParty]
                ).length /
                  dataMapCandidates.filter(
                    d =>
                      d.party.key === MSP.Party[MSP.Party.ScottishNationalParty]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key ===
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length /
                  dataMapCandidates.filter(
                    d =>
                      d.party.key ===
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
            <p>-</p>
            <p>
              {" : "}
              {Math.round(
                (dataMapCandidates.filter(
                  d =>
                    d.support === MSP.Support.Yes &&
                    d.party.key !== MSP.Party[MSP.Party.ScottishLabourParty] &&
                    d.party.key !== MSP.Party[MSP.Party.ScottishGreenParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishLiberalDemocrats] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishNationalParty] &&
                    d.party.key !==
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                ).length /
                  dataMapCandidates.filter(
                    d =>
                      d.party.key ===
                      MSP.Party[MSP.Party.ScottishConservativeParty]
                  ).length) *
                  100
              )}
              {"%"}
            </p>
          </div>
        </div>
        <p>
          <b>
            Are you an MSP or candidate who wants to make sure your views are
            represented here?
          </b>
        </p>
        <p>
          Read our <a href={"/request"}>request</a> and{" "}
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            email us
          </a>{" "}
          and we'll make sure your response is listed here.
        </p>
        <p>
          Our email address is{" "}
          <a href="mailto:endconversiontherapyscotland@gmail.com?subject=Candidate Reply">
            endconversiontherapyscotland@gmail.com
          </a>
          .
        </p>
        <p>
          If we know an email has been sent to an incumbent MSP by a constituent
          they will have "Have Been Contacted" confirmed below.
        </p>
        <div className="select-buttons">
          <button
            disabled={
              this.state.SectionSelected === SelectedSection.AllCandidates
            }
            onClick={this.SelectAllCandidates}
          >
            All Candidates
          </button>
          <button
            disabled={this.state.SectionSelected === SelectedSection.Incumbents}
            onClick={this.SelectIncumbents}
          >
            Incumbent MSPs
          </button>
        </div>
        {this.state.SectionSelected === SelectedSection.Incumbents && (
          <>
            <h1>Incumbent MSPs</h1>
            <BootstrapTable
              keyField="name"
              data={dataMap}
              columns={columns}
              bordered={true}
              filter={filterFactory()}
            />

            <h1 style={{ maxWidth: "1000px" }}>Incumbent MSPs Standing Down</h1>
            <BootstrapTable
              keyField="name"
              data={dataMap2}
              columns={columns2}
              bordered={true}
              filter={filterFactory()}
            />
          </>
        )}
        {this.state.SectionSelected === SelectedSection.AllCandidates && (
          <>
            <h1>Candidates</h1>
            <h3>Incumbent MSPs are in bold</h3>
            <BootstrapTable
              keyField="name"
              data={dataMapCandidates}
              columns={columns3}
              bordered={true}
              filter={filterFactory()}
            />
          </>
        )}
      </div>
    );
  }

  private FormatContacted(value: boolean): JSX.Element {
    switch (value) {
      case true:
        return <b style={{ margin: 0, color: "#06a800" }}>{"Yes"}</b>;
      case false:
        return <p style={{ margin: 0, color: "#333333" }}>{"No"}</p>;
    }
  }

  private FormatSupport(value: MSP.Support): JSX.Element {
    switch (value) {
      case MSP.Support.NoReply:
        return (
          <p style={{ margin: 0, color: "#555555" }}>
            {this.CamelCaseToSentence(MSP.Support[value])}
          </p>
        );
      case MSP.Support.No:
        return (
          <p style={{ margin: 0, color: "#a80000", fontWeight: "bold" }}>
            {this.CamelCaseToSentence(MSP.Support[value])}
          </p>
        );
      case MSP.Support.Yes:
        return (
          <p style={{ margin: 0, color: "#06a800", fontWeight: "bold" }}>
            {this.CamelCaseToSentence(MSP.Support[value])}
          </p>
        );
      case MSP.Support.YesWithCaveats:
        return (
          <p style={{ margin: 0, color: "#EE9900", fontWeight: "bold" }}>
            {this.CamelCaseToSentence(MSP.Support[value])}
          </p>
        );
      default:
        return <></>;
    }
  }

  private FormatRegion(
    constituency?: MSP.Constituency,
    region?: MSP.Region
  ): string {
    if (region) {
      return this.CamelCaseToSentence(MSP.Region[region]);
    } else if (!constituency) {
      return "none";
    } else {
      const newRegion = ConstituencyToRegionMap.Collection.find(
        r => r.const === constituency
      )?.region;
      if (newRegion) {
        return this.CamelCaseToSentence(MSP.Region[newRegion]);
      } else {
        return "none";
      }
    }
  }

  private CamelCaseToSentence(passed: string) {
    const result = passed.replace(/([A-Z])/g, " $1");
    let final = result.charAt(0).toUpperCase() + result.slice(1);
    final = final.replace("And", "and");
    return final;
  }
  private FormatParty(party: MSP.Party) {
    switch (party) {
      case MSP.Party.ScottishConservativeParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishConservativeParty]}>
            <img
              className={"con logo"}
              src={Tories}
              alt={"Conservative logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishGreenParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishGreenParty]}>
            <img className={"green logo"} src={Greens} alt={"green logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishLiberalDemocrats:
        return (
          <div
            key={
              MSP.Party[MSP.Party.ScottishLiberalDemocrats]
            } /*className="pledged-party-text"*/
          >
            <img className={"libdem logo"} src={LibDems} alt={"libdem logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
            {/*<img className={"tick"} src={//Tick} alt={"party pledge tick"} />*/}
          </div>
        );
      case MSP.Party.ScottishLabourParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishLabourParty]}>
            <img className={"labour logo"} src={Labour} alt={"labour logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishNationalParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishNationalParty]}>
            <img className={"snp logo"} src={SNP} alt={"snp logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ReformUkScotland:
        return (
          <div key={MSP.Party[MSP.Party.ReformUkScotland]}>
            <img className={"reform logo"} src={ReformUK} alt={"reform logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.AllForUnity:
        return (
          <div key={MSP.Party[MSP.Party.AllForUnity]}>
            <img className={"a4u logo"} src={All4Unity} alt={"a4u logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishFamilyParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishFamilyParty]}>
            <img
              className={"family logo"}
              src={FamilyParty}
              alt={"family logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.UkIndependenceParty:
        return (
          <div key={MSP.Party[MSP.Party.UkIndependenceParty]}>
            <img className={"ukip logo"} src={UKIP} alt={"ukip logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.AlbaParty:
        return (
          <div key={MSP.Party[MSP.Party.AlbaParty]}>
            <img className={"alba logo"} src={AlbaParty} alt={"alba logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.AbolishTheScottishParliamentParty:
        return (
          <div key={MSP.Party[MSP.Party.AbolishTheScottishParliamentParty]}>
            <img
              className={"abolish logo"}
              src={Abolish}
              alt={"abolish logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.CommunistPartyOfBritain:
        return (
          <div key={MSP.Party[MSP.Party.CommunistPartyOfBritain]}>
            <img
              className={"communists logo"}
              src={Communists}
              alt={"communists logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.FreedomAlliance:
        return (
          <div key={MSP.Party[MSP.Party.FreedomAlliance]}>
            <img
              className={"freedom logo"}
              src={Freedom}
              alt={"freedom logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.IndependentGreenVoice:
        return (
          <div key={MSP.Party[MSP.Party.IndependentGreenVoice]}>
            <img className={"igv logo"} src={IGV} alt={"igv logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.LiberalParty:
        return (
          <div key={MSP.Party[MSP.Party.LiberalParty]}>
            <img
              className={"liberal logo"}
              src={Liberal}
              alt={"liberal logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishLibertarianParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishLibertarianParty]}>
            <img
              className={"libertarian logo"}
              src={Libertarians}
              alt={"libertarian logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ReclaimParty:
        return (
          <div key={MSP.Party[MSP.Party.ReclaimParty]}>
            <img
              className={"reclaim logo"}
              src={Reclaim}
              alt={"reclaim logo"}
            />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishRenew:
        return (
          <div key={MSP.Party[MSP.Party.ScottishRenew]}>
            <img className={"renew logo"} src={Renew} alt={"renew logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScotiaFuture:
        return (
          <div key={MSP.Party[MSP.Party.ScotiaFuture]}>
            <img className={"scotia logo"} src={Scotia} alt={"scotia logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.SocialDemocraticParty:
        return (
          <div key={MSP.Party[MSP.Party.SocialDemocraticParty]}>
            <img className={"sdp logo"} src={SDP} alt={"sdp logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.TradeUnionistAndSocialistCoalition:
        return (
          <div key={MSP.Party[MSP.Party.TradeUnionistAndSocialistCoalition]}>
            <img className={"tusc logo"} src={TUSC} alt={"tusc logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      case MSP.Party.ScottishWomensEqualityParty:
        return (
          <div key={MSP.Party[MSP.Party.ScottishWomensEqualityParty]}>
            <img className={"wep logo"} src={WEP} alt={"wep logo"} />
            {this.CamelCaseToSentence(MSP.Party[party])}
          </div>
        );
      default:
        return (
          <div key={party.toString()}>
            <div
              className={"logo"}
              style={{ height: "20px", display: "inline-block" }}
            >
              {""}
            </div>
            <div style={{ height: "20px", display: "inline" }}>
              {this.CamelCaseToSentence(MSP.Party[party])}
            </div>
          </div>
        );
    }
  }

  private FormatName(passed: string, incumbent: boolean, support: MSP.Support) {
    let text = passed.trim();
    if (passed.includes(",")) {
      const names = passed.split(",");
      text = names[1] + " " + names[0];
    }
    text = text.trim();
    if (incumbent) {
      switch (support) {
        case MSP.Support.NoReply:
          return (
            <p
              key={text}
              style={{ margin: 0, color: "#555555", fontWeight: "bold" }}
            >
              {text}
            </p>
          );
        case MSP.Support.No:
          return (
            <p
              key={text}
              style={{ margin: 0, color: "#a80000", fontWeight: "bold" }}
            >
              {text}
            </p>
          );
        case MSP.Support.Yes:
          return (
            <p
              key={text}
              style={{ margin: 0, color: "#06a800", fontWeight: "bold" }}
            >
              {text}
            </p>
          );
        case MSP.Support.YesWithCaveats:
          return (
            <p
              key={text}
              style={{ margin: 0, color: "#EE9900", fontWeight: "bold" }}
            >
              {text}
            </p>
          );
        default:
          return <></>;
      }
    } else {
      switch (support) {
        case MSP.Support.NoReply:
          return (
            <p key={text} style={{ margin: 0, color: "#555555" }}>
              {text}
            </p>
          );
        case MSP.Support.No:
          return (
            <p key={text} style={{ margin: 0, color: "#a80000" }}>
              {text}
            </p>
          );
        case MSP.Support.Yes:
          return (
            <p key={text} style={{ margin: 0, color: "#06a800" }}>
              {text}
            </p>
          );
        case MSP.Support.YesWithCaveats:
          return (
            <p key={text} style={{ margin: 0, color: "#EE9900" }}>
              {text}
            </p>
          );
        default:
          return <></>;
      }
    }
  }

  private ConvertIncumbentName(passed: string): string {
    const names = passed.split(",");
    return (names[1] + " " + names[0]).trim();
  }

  private SortConstituency = (
    collection: IMSPSupport[],
    constituency: MSP.Constituency
  ) => {
    collection.sort((n1, n2) => {
      if (
        (n1.constituency === constituency ||
          n2.constituency === constituency) &&
        (n1.constituency !== constituency || n2.constituency !== constituency)
      ) {
        const aValue = n1.constituency === constituency ? 1 : 0;
        const bValue = n2.constituency === constituency ? 1 : 0;
        return aValue < bValue ? 1 : -1;
      } else {
        if (
          n1.party.key !== null &&
          n2.party.key !== null &&
          n1.party.key !== n2.party.key
        ) {
          return n1.party.key < n2.party.key ? 1 : -1;
        }
        if (
          (n1.constituency === constituency &&
            n2.constituency === constituency) ||
          n1.regionRank === undefined ||
          n2.regionRank === undefined
        ) {
          if (
            (n1.name.key ? n1.name.key : "a") >
            (n2.name.key ? n2.name.key : "a")
          ) {
            return 1;
          }

          if (
            (n1.name.key ? n1.name.key : "a") <
            (n2.name.key ? n2.name.key : "a")
          ) {
            return -1;
          }
          return 0;
        } else {
          return n1.regionRank > n2.regionRank ? 1 : -1;
        }
      }
    });
  };

  private SortCandidates = (collection: CandidateInfo[]) => {
    collection.sort((n1, n2) => {
      if (
        (n1.name.key ? n1.name.key : "a") > (n2.name.key ? n2.name.key : "a")
      ) {
        return 1;
      }

      if (
        (n1.name.key ? n1.name.key : "a") < (n2.name.key ? n2.name.key : "a")
      ) {
        return -1;
      }

      return 0;
    });
  };

  private SortIncumbents = (collection: IncumbentInfo[]) => {
    collection.sort((n1, n2) => {
      if (
        (n1.name.key ? n1.name.key : "a") > (n2.name.key ? n2.name.key : "a")
      ) {
        return 1;
      }

      if (
        (n1.name.key ? n1.name.key : "a") < (n2.name.key ? n2.name.key : "a")
      ) {
        return -1;
      }

      return 0;
    });
  };

  private SelectAllCandidates = () => {
    this.setState({ SectionSelected: SelectedSection.AllCandidates });
  };

  private SelectIncumbents = () => {
    this.setState({ SectionSelected: SelectedSection.Incumbents });
  };

  private readonly ConstituencyMap = (
    selected: ValueType<IConstituencies, false>,
    action: ActionMeta<IConstituencies>
  ): void => {
    if (selected) {
      const result = new Array<IMSPSupport>();
      const msps = this.GetConstituencyMSPs(selected.value);
      msps.forEach(m =>
        result.push({
          name: this.FormatName(m.Name, false, m.Support),
          party: this.FormatParty(m.Party),
          support: m.Support,
          constituency: m.Constituency,
          statement: m.Statement,
          regionRank: m.RegionRank
        })
      );
      this.SortConstituency(result, selected.value);
      this.setState({
        Constituency: {
          constituency: selected.value,
          msps: result
        }
      });
    }
  };

  private GetConstituencyMSPs(constituency: MSP.Constituency): MSP.MSP[] {
    const region = ConstituencyToRegionMap.Collection.find(
      r => r.const === constituency
    )?.region;
    const constituencyCandidates = Candidates.MSPs.MSPsCollection.filter(
      m => m.Constituency === constituency
    );
    const regionalCandidates = Candidates.MSPs.MSPsCollection.filter(
      m => m.Region === region
    );
    constituencyCandidates.forEach(c => {
      if (!regionalCandidates.map(r => r.Name).includes(c.Name)) {
        regionalCandidates.push(c);
      }
    });
    regionalCandidates.concat(constituencyCandidates);
    const constituencyMSP = MSPs.MSPs.MSPsCollection.find(
      m => m.Constituency === constituency && !m.StandingDown
    );
    if (constituencyMSP) {
      const candidate = regionalCandidates.find(
        m => m.Name.trim() === this.ConvertIncumbentName(constituencyMSP.Name)
      );
      if (!candidate) {
        regionalCandidates.push(constituencyMSP);
      }
    }
    return regionalCandidates;
  }
}

export default TablePage;
