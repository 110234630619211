export interface MSP {
  Name: string;
  Party: Party;
  Constituency?: Constituency;
  Region?: Region;
  Support: Support;
  RegionRank?: number;
  Statement: string;
  StandingDown?: boolean;
  HaveBeenContacted?: boolean;
  Incumbent?: boolean;
  Elected?: boolean;
}

export enum Party {
  ScottishConservativeParty,
  ScottishGreenParty,
  ScottishLabourParty,
  ScottishLiberalDemocrats,
  ScottishNationalParty,
  Independent,
  ReformUkScotland,
  ScotiaFuture,
  ScottishFamilyParty,
  AllForUnity,
  CommunistPartyOfBritain,
  UkIndependenceParty,
  TradeUnionistAndSocialistCoalition,
  AlbaParty,
  AbolishTheScottishParliamentParty,
  FreedomAlliance,
  RestoreScotland,
  ScottishLibertarianParty,
  IndependentGreenVoice,
  ScottishRenew,
  ReclaimParty,
  ScottishWomensEqualityParty,
  LiberalParty,
  SocialDemocraticParty,
  VanguardParty
}
export enum Constituency {
  AberdeenCentral = 1,
  AberdeenDonside,
  AberdeenSouthAndNorthKincardine,
  AberdeenshireEast,
  AberdeenshireWest,
  AirdrieAndShotts,
  AlmondValley,
  AngusNorthAndMearns,
  AngusSouth,
  ArgyllAndBute,
  Ayr,
  BanffshireAndBuchanCoast,
  CaithnessSutherlandAndRoss,
  CarrickCumnockAndDoonValley,
  ClackmannanshireAndDunblane,
  ClydebankAndMilngavie,
  Clydesdale,
  CoatbridgeAndChryston,
  Cowdenbeath,
  CumbernauldAndKilsyth,
  CunninghameNorth,
  CunninghameSouth,
  Dumbarton,
  Dumfriesshire,
  Dunfermline,
  DundeeCityEast,
  DundeeCityWest,
  EastKilbride,
  EastLothian,
  Eastwood,
  EdinburghCentral,
  EdinburghEastern,
  EdinburghNorthernAndLeith,
  EdinburghPentlands,
  EdinburghSouthern,
  EdinburghWestern,
  EttrickRoxburghAndBerwickshire,
  FalkirkEast,
  FalkirkWest,
  GallowayAndWestDumfries,
  GlasgowAnniesland,
  GlasgowCathcart,
  GlasgowKelvin,
  GlasgowMaryhillAndSpringburn,
  GlasgowPollok,
  GlasgowProvan,
  GlasgowShettleston,
  GlasgowSouthside,
  GreenockAndInverclyde,
  HamiltonLarkhallAndStonehouse,
  InvernessAndNairn,
  KilmarnockAndIrvineValley,
  Kirkcaldy,
  Linlithgow,
  MidlothianNorthAndMusselburgh,
  MidlothianSouthTweedaleAndLauderdale,
  MidFifeAndGlenrothes,
  MotherwellAndWishaw,
  Moray,
  NaHEileananAnIar,
  NorthEastFife,
  Orkney,
  Paisley,
  PerthshireNorth,
  PerthshireSouthAndKinrossShire,
  RenfrewshireNorthAndWest,
  RenfrewshireSouth,
  Rutherglen,
  Shetland,
  SkyeLochaberAndBadenoch,
  Stirling,
  StrathkelvinAndBearsden,
  UddingstonAndBellshill
}
export enum Region {
  Glasgow = 1,
  Lothian,
  HighlandsAndIslands,
  SouthScotland,
  MidScotlandAndFife,
  NorthEastScotland,
  WestScotland,
  CentralScotland
}
export enum Support {
  Yes,
  No,
  YesWithCaveats,
  NoReply
}
