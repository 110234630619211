import * as MSP from "../DTOs/Types";

export class MSPs {
  public static MSPsCollection: MSP.MSP[] = [
    {
      Name: "Adam,George",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Paisley,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Adamson,Clare",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MotherwellAndWishaw,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "There is no place for conversion therapy in Scotland. Such practices are discriminatory and harmful to the mental health and wellbeing of the LGBT+ community. I am proud to back the End Conversion Therapy campaign.",
      HaveBeenContacted: false
    },
    {
      Name: "Allan,Alasdair",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.NaHEileananAnIar,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Arthur,Tom",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.RenfrewshireSouth,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: false
    },
    {
      Name: "Baillie,Jackie",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.Dumbarton,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Baker,Claire",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Balfour,Jeremy",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Ballantyne,Michelle",
      Party: MSP.Party.ReformUkScotland,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Beamish,Claudia",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Beattie,Colin",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidlothianNorthAndMusselburgh,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: false
    },
    {
      Name: "Bibby,Neil",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Bowman,Bill",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Boyack,Sarah",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Briggs,Miles",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: false
    },
    {
      Name: "Brown,Keith",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ClackmannanshireAndDunblane,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Burnett,Alexander",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.AberdeenshireWest,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Cameron,Donald",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Campbell,Aileen",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Clydesdale,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Carlaw,Jackson",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Eastwood,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "This is an abhorrent practice that has no place in Scotland or the UK.",
      HaveBeenContacted: false
    },
    {
      Name: "Carson,Finlay",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.GallowayAndWestDumfries,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Chapman,Peter",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Coffey,Willie",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.KilmarnockAndIrvineValley,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Cole-Hamilton,Alex",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.EdinburghWestern,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "I am fully supportive of this- conversion therapy causes a great deal of harm and I fully back legislation to stop it. It goes against liberal values of equality.",
      HaveBeenContacted: false
    },
    {
      Name: "Constance,Angela",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AlmondValley,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Corry,Maurice",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Crawford,Bruce",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Stirling,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Cunningham,Roseanna",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.PerthshireSouthAndKinrossShire,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Davidson,Ruth",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EdinburghCentral,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Denham,Ash",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghEastern,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Dey,Graeme",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AngusSouth,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Doris,Bob",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowMaryhillAndSpringburn,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "We should seek to legislate with existing powers. If they are insufficient that will become apparent. I was given reassurances from campaign that is not the case. Unequivocal support.",
      HaveBeenContacted: true
    },
    {
      Name: "Dornan,James",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowCathcart,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "I support the moves to end conversion therapy.",
      HaveBeenContacted: false
    },
    {
      Name: "Ewing,Fergus",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.InvernessAndNairn,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Ewing,Annabelle",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Cowdenbeath,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Fabiani,Linda",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EastKilbride,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Fee,Mary",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Findlay,Neil",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Finnie,John",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "FitzPatrick,Joe",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.DundeeCityWest,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "It's a bit of a shame on us all that we're in this day and age, with so much reforms and yet this kind of behaviour is still legal in Scotland. I think potentially this is an area where the [Equalities and Human Rights] Committee might want to consider whether this is appropriate for a Committee Bill. Might not get to the point of being a priority for any government in th efuture, and if that's the case then I think the Committee should look as to whether we can use the particular powers we have in the Scottish Parliament to take forward Committee legislation to basically put it beyond doubt that this kind of behaviour is not acceptable in Scotland.",
      HaveBeenContacted: false
    },
    {
      Name: "Forbes,Kate",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.SkyeLochaberAndBadenoch,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Fraser,Murdo",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Freeman,Jeane",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CarrickCumnockAndDoonValley,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: true
    },
    {
      Name: "Gibson,Kenneth",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CunninghameNorth,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Gilruth,Jenny",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidFifeAndGlenrothes,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Golden,Maurice",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Gougeon,Mairi",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AngusNorthAndMearns,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Grahame,Christine",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.MidlothianSouthTweedaleAndLauderdale,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Grant,Rhoda",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Gray,Iain",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EastLothian,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Greene,Jamie",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: true
    },
    {
      Name: "Greer,Ross",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "'Conversion therapy' is a benign way to describe abuse. I'm proud that the Scottish Greens manifesto includes an unequivocal commitment to ban it. If re-elected I would work to ensure we explore the quickest and most effective way to do this as soon as Parliament is reconvened.",
      HaveBeenContacted: true
    },
    {
      Name: "Griffin,Mark",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: true
    },
    {
      Name: "HalcroJohnston,Jamie",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Hamilton,Rachael",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.EttrickRoxburghAndBerwickshire,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Harper,Emma",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Harris,Alison",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Harvie,Patrick",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Haughey,Clare",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Rutherglen,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Hepburn,Jamie",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CumbernauldAndKilsyth,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: true
    },
    {
      Name: "Hyslop,Fiona",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Linlithgow,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Johnson,Daniel",
      Party: MSP.Party.ScottishLabourParty,
      Constituency: MSP.Constituency.EdinburghSouthern,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Johnstone,Alison",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "I wholeheartedly support the campaign to end the inhumane and damaging practice of conversion therapy.",
      HaveBeenContacted: false
    },
    {
      Name: "Kelly,James",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Kerr,Liam",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Kidd,Bill",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowAnniesland,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Lamont,Johann",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false,
      StandingDown: true
    },
    {
      Name: "Lennon,Monica",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "Conversion therapy is a form of psychological abuse that can have a lasting impact on LGBT+ people and I wholly support the work the End Conversion Therapy campaign is doing to put an end to this abusive practice.  Your efforts to support LGBT+ young people and adults affected by conversion therapy is to be commended. I am fully behind your calls for a criminal ban in Scotland and I pledge to support any legislation to that effect.  I agree with you that, as well as the provision of conversion therapy, any ban must also include causing a person to undergo conversion therapy; removing a minor from Scotland to undergo conversion therapy abroad; and advertising an offer to provide conversion therapy. As you have outlined, I believe it is important that we engage with religious leaders on the impact of certain teachings on their LGBT members.  It is crucial that we also establish a public complaints mechanism for reports of conversion therapy and ensure there is support for survivors as well as awareness training for both the healthcare sector and religious organisations. One LGBT+ person being forced to undergo the trauma of conversion therapy is one too many.  I want to assure you of my unwavering support for your campaign and I pledge that I will be your friend and ally in putting an end to this shocking practice.",
      HaveBeenContacted: true
    },
    {
      Name: "Leonard,Richard",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Lindhurst,Gordon",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Lochhead,Richard",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Moray,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Lockhart,Dean",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Lyle,Richard",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.UddingstonAndBellshill,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "MacDonald,Gordon",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghPentlands,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "MacDonald,Angus",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.FalkirkEast,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Macdonald,Lewis",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "MacGregor,Fulton",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CoatbridgeAndChryston,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "I commit to supporting the end of conversion therapy. This practice is discriminatory and harmful to LGBT people and absolutely must be stopped.",
      HaveBeenContacted: true
    },
    {
      Name: "Macintosh,Ken",
      Party: MSP.Party.Independent,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Mackay,Derek",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.RenfrewshireNorthAndWest,
      Region: MSP.Region.WestScotland,
      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false,
      StandingDown: true
    },
    {
      Name: "Mackay,Rona",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.StrathkelvinAndBearsden,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "I want to end conversion therapy. It is abhorrent.",
      HaveBeenContacted: true
    },
    {
      Name: "Macpherson,Ben",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.EdinburghNorthernAndLeith,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support. There is absolutely no place for so-called conversion therapy in Scotland. Such practices are discriminatory, and harmful to the mental health and wellbeing of LGBTI people.",
      HaveBeenContacted: true
    },
    {
      Name: "Maguire,Ruth",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CunninghameSouth,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Marra,Jenny",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Martin,Gillian",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenshireEast,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "Happy to support your campaign to end conversion therapy.",
      HaveBeenContacted: false
    },
    {
      Name: "Mason,Tom",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Mason,John",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowShettleston,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Matheson,Michael",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.FalkirkWest,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "McAlpine,Joan",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "McArthur,Liam",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Orkney,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "This is an important campaign and one that Scottish Liberal Democrats strongly support.  I wish you every success and look forward to helping play my part.",

      HaveBeenContacted: false
    },
    {
      Name: "McDonald,Mark",
      Party: MSP.Party.Independent,
      Constituency: MSP.Constituency.AberdeenDonside,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "McKee,Ivan",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowProvan,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "McKelvie,Christina",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.HamiltonLarkhallAndStonehouse,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "I confirm that I support this campaign in my capacity as an MSP and Candidate",
      HaveBeenContacted: false
    },
    {
      Name: "McMillan,Stuart",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GreenockAndInverclyde,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "McNeill,Pauline",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: true
    },
    {
      Name: "Mitchell,Margaret",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Mountain,Edward",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Mundell,Oliver",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Dumfriesshire,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Neil,Alex",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AirdrieAndShotts,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Paterson,Gil",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ClydebankAndMilngavie,
      Region: MSP.Region.WestScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Rennie,Willie",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.NorthEastFife,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "If we are serious about wanting Scotland to be the best country in the world for LGBT+ people, then that means using the powers of the Scottish government to challenge hatred and discrimination, no matter where it comes from.  The End Conversion Therapy campaign have done valuable work keeping this on the political agenda by asking candidates in this May’s election to back their pledge. I would like to go one step further and commit that my party will back legislation for a ban on conversion therapy for LGBT+ people. This legislation would set in law sanctions for causing a person causing a person to undergo conversion therapy providing or advertising conversion therapy and importantly, removing a minor from Scotland to undergo conversion therapy abroad. I think that Scotland can be genuinely proud of the work that it has done to improve LGBT+ rights but there is plenty more to do. That’s why I’m proud to pledge my party’s support in ensuring this practice must be ended once and for all.",
      HaveBeenContacted: false
    },
    {
      Name: "Robison,Shona",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.DundeeCityEast,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Ross,Gail",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Rowley,Alex",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Rumbles,Mike",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Ruskell,Mark",
      Party: MSP.Party.ScottishGreenParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "We in the Scottish Greens have long called for banning of conversion therapy in Scotland, conversion therapy is harmful, immoral and wrong.  We will continue to make that argument both in and out of the Scottish Parliament.",
      HaveBeenContacted: false
    },
    {
      Name: "Russell,Michael",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.ArgyllAndBute,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Sarwar,Anas",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "You have my full support in your campaign. This is an important campaign and I am more than happy to support it.",
      HaveBeenContacted: true
    },
    {
      Name: "Scott,John",
      Party: MSP.Party.ScottishConservativeParty,
      Constituency: MSP.Constituency.Ayr,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Simpson,Graham",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Smith,Liz",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Smith,Elaine",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.CentralScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Smyth,Colin",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Somerville,Shirley-Anne",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Dunfermline,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "",
      HaveBeenContacted: true
    },
    {
      Name: "Stevenson,Stewart",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.BanffshireAndBuchanCoast,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Stewart,David",
      Party: MSP.Party.ScottishLabourParty,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Stewart,Alexander",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Stewart,Kevin",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenCentral,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement: "I am happy to sign your pledge.",
      HaveBeenContacted: false
    },
    {
      Name: "Sturgeon,Nicola",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowSouthside,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Swinney,John",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.PerthshireNorth,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    },
    {
      Name: "Todd,Maree",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.HighlandsAndIslands,
      Constituency: MSP.Constituency.CaithnessSutherlandAndRoss,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Tomkins,Adam",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Torrance,David",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.Kirkcaldy,
      Region: MSP.Region.MidScotlandAndFife,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Watt,Maureen",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.AberdeenSouthAndNorthKincardine,
      Region: MSP.Region.NorthEastScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Wells,Annie",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Wheelhouse,Paul",
      Party: MSP.Party.ScottishNationalParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "White,Sandra",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowKelvin,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      StandingDown: true,
      HaveBeenContacted: false
    },
    {
      Name: "Whittle,Brian",
      Party: MSP.Party.ScottishConservativeParty,
      Region: MSP.Region.SouthScotland,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: true
    },
    {
      Name: "Wightman,Andy",
      Party: MSP.Party.Independent,
      Region: MSP.Region.Lothian,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "Yes, I support this campaign though as with all campaigns that involve creating criminal offences, further detailed thought is needed to ensure that people are not inadvertently caught by the criminal law. As with everything there are issues that will be brought up in consultation - for example how to protect health professionals etc. I think the state of Victoria in Australia has brought in some good legislation in this areas. So yes, I support the general principles set out in your request",
      HaveBeenContacted: false
    },
    {
      Name: "Wishart,Beatrice",
      Party: MSP.Party.ScottishLiberalDemocrats,
      Constituency: MSP.Constituency.Shetland,
      Region: MSP.Region.HighlandsAndIslands,

      RegionRank: undefined,
      Support: MSP.Support.Yes,
      Statement:
        "The Scottish Liberal Democrats rightfully voted to overwhelmingly oppose the practice of Conversion Therapy for LGBT+ youth at the party’s conference in 2014. I am more than willing to reaffirm our commitment to bring about an end to this harmful practice and offer my full support to this campaign.",
      HaveBeenContacted: false
    },
    {
      Name: "Yousaf,Humza",
      Party: MSP.Party.ScottishNationalParty,
      Constituency: MSP.Constituency.GlasgowPollok,
      Region: MSP.Region.Glasgow,

      RegionRank: undefined,
      Support: MSP.Support.NoReply,
      Statement: "none",
      HaveBeenContacted: false
    }
  ];
}
