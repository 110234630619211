import React from "react";
import "../App.css";

class PetitionPage extends React.Component<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className="App">
        <h1>Scottish Parliamentary Petition</h1>
        <p>
          We submitted a petition to the Scottish Parliamentary Petitions
          Committee on 13 August 2020.
        </p>
        <p>
          The petition was considered by the committee on 1 October 2020. 1
          October 2020: The Committee agreed to refer the petition to the
          Equalities and Human Rights Committee.{" "}
          <a
            href={
              "http://www.parliament.scot/parliamentarybusiness/report.aspx?r=12874"
            }
          >
            Link to Official Report of Meeting 1 October 2020
          </a>
        </p>
        <p>
          The petition was been referred to the Equalities and Human Rights
          Committee. You can view any further updates on this petition on the{" "}
          <a
            href={
              "https://www.parliament.scot/parliamentarybusiness/CurrentCommittees/Equalities-Committee.aspx"
            }
          >
            Equalities and Human Rights Committee's webpage
          </a>
        </p>
        <h2>Committee Response</h2>
        <p>The committee has now reported back on their findings during the petition process. You can find their report here: {" "}
            <a href={"https://digitalpublications.parliament.scot/Committees/Report/EHRCJ/2022/1/25/8c18e05c-08ab-4c7d-992b-4b0467541d70#Introduction"}>
            Equalities and Human Rights Committee's report
            </a>
          </p>
          <h3>Campaign and LGBT+ Sector Response</h3>
          <p><strong>End Conversion Therapy Scotland welcomes Holyrood Committee report into a ban on Conversion Practices</strong></p>
<p>Scotland&rsquo;s major LGBT+ groups and the End Conversion Therapy Campaign have welcomed a report from the Scottish Parliament&rsquo;s equalities committee which recommends new legislation to ban conversion practices in Scotland. &ldquo;The Committee agrees that conversion practices are abhorrent and are not acceptable in Scotland. They should be banned.&rdquo;&nbsp;</p>
<p>The Equalities, Human Rights and Civil Justice Committee has today published its report on the <em>&ldquo;End Conversion Therapy&rdquo; </em>petition introduced by End Conversion Therapy Scotland<em>.</em></p>
<p>Summarising the evidence, the Committee today recommends that new legislation is brought forward &ldquo;promptly&rdquo; to comprehensively ban the practice in all its forms. End Conversion Therapy Scotland have called this &ldquo;a welcome and vital step towards ending the abuse and torture of LGBTQ+ people in Scotland&rdquo;.</p>
<p>Survivors of conversion practices and a number of leading LGBT+ organisations - LGBT Youth Scotland, Stonewall Scotland, Equality Network and Scottish Trans - were among those invited to give evidence and have also welcomed the Committee&rsquo;s recommendations for swift action.&nbsp;</p>
<p>Dr Rebecca Crowther, Policy Coordinator at Equality Network says:</p>
<p><strong><em>&ldquo;We are delighted to see the recommendations made by the Committee and look forward to the next stages, ensuring that these harmful practices are finally put to an end across Scotland. It is thanks to the testimony of survivors of these abhorrent practices that the Committee has made these recommendations - We want to thank them for standing up and sharing their stories so that these practices could finally be stopped.&rdquo;&nbsp;</em></strong></p>
<p>Megan Snedden, Policy and Campaigns Manager at Stonewall Scotland says:&nbsp;</p>
<p><strong><em>&ldquo;A fully comprehensive ban on conversion practices is necessary to protect LGBTQ+ communities in Scotland from harm. That&rsquo;s why we welcome that the Committee&rsquo;s report makes clear that a ban must cover sexual orientation and gender identity, both adults and children, all settings, and with no exceptions for so-called &lsquo;consensual&rsquo; practices. We&rsquo;ll continue to work with MSPs and the Scottish Government to ensure that Scotland delivers a comprehensive ban on conversion practices in the most effective manner and without delay.&rdquo;</em></strong></p>
<p>&ldquo;Conversion therapy&rdquo; is the misleading name for practices and directive behaviour intended to change or suppress someone&rsquo;s sexual orientation or gender identity. Conversion practices are based on the misconception that these can be changed and on phobic beliefs that being LGBT+ is wrong or in need of being &lsquo;fixed.&rsquo; There is nothing therapeutic about them. The Committee heard from a number of survivors of conversion practices that had taken place in Scotland.&nbsp;</p>
<p>When giving evidence before the Committee, Blair Anderson of End Conversion Therapy Scotland who is a gay man who was put through conversion practices as a child, said:&nbsp;</p>
<p><strong><em>&ldquo;Conversion &lsquo;therapy&rsquo; is not a positive, therapeutic or beneficial treatment. It is often seen as a form of torture. The International Rehabilitation for Torture Victims said that conversion &lsquo;therapy&rsquo; violates the global ban on torture. People cannot consent to torture. It is not possible to change your sexuality or your gender identity. Conversion &lsquo;therapy&rsquo; cannot work. We consider conversion practices to be a form of torture and it does not work.&rdquo;</em></strong><a href="https://twitter.com/SP_EHRCJ/status/1437339035664621570?s=20">Source.</a></p>
<p>A spokesperson for End Conversion Therapy Scotland said:</p>
<p><strong><em>&ldquo;We are grateful to the Committee for setting out so clearly the case for a comprehensive and swift ban on the torture of LGBT+ people in Scotland.&nbsp;</em></strong></p>
<p><strong><em>&ldquo;We have been clear from the outset of our campaign: any conversion practices ban must be fully comprehensive, protecting all of Scotland&rsquo;s queer people from conversion practices, in all its forms. We are glad that the Committee has resisted efforts from those who practice, or who support these practices, to muddy the waters of this debate.&nbsp;</em></strong></p>
<p><strong><em>&ldquo;It is positive to see the Committee note their concern regarding how long it has taken to reach this point - and we fully back their calls for the Scottish Government to work with Holyrood to ensure legislation is brought forward as soon as possible.&rdquo;&nbsp;</em></strong></p>
<p><strong><em>&ldquo;We look forward to seeing the day when all LGBTQ+ people are allowed to be who they are, free from hate or persecution, and we hope that day is soon.&rdquo;</em></strong></p>
        <br />
        <h2>
          Stonewall Scotland, Equality Network, Scottish Trans Alliance, and
          LGBT Youth Scotland
        </h2>
        <h3>
          Submission to the Scottish Parliament Public Petitions Committee of 23
          September 2020
        </h3>
        <p>
          Stonewall Scotland, Equality Network, Scottish Trans Alliance, and
          LGBT Youth Scotland support the principles of petition PE1817 to “urge
          the Scottish Government to ban the provision or promotion of LGBT+
          conversion therapy in Scotland.” This submission sets out our
          proposals for ending the harmful practice of sexual orientation and
          gender identity conversion therapy in Scotland, and commitments we
          would like to see from the Scottish Government.
        </p>
        <h3>Background</h3>
        <p>
          In June 2020, the UN Independent Expert on protection against violence
          and discrimination based on sexual orientation and gender identity
          called for a global ban on conversion therapy, stating: “practices of
          ‘conversion therapy’, based on the incorrect and harmful notion that
          sexual and gender diversity are disorders to be corrected, are
          discriminatory in nature… actions to subject lesbians, gay, bisexual,
          trans or gender-diverse persons to practices of ‘conversion therapy’
          are by their very nature degrading, inhuman and cruel and create a
          significant risk of torture.” At a domestic level, NHS Scotland, in
          addition to a range of national bodies such as the British
          Psychological Society and the Royal College of General Practitioners,
          signed the Memorandum of Understanding (MoU) on Conversion Therapy
          (2017), which committed to ending the practice of conversion therapy
          in the UK. It states that conversion therapy, whether in relation to
          sexual orientation or gender identity, is unethical and potentially
          harmful. The MoU commits these organisations to making the risks of
          conversion therapy known to professionals and the public, and to
          ensuring that new and existing psychological therapists are
          appropriately trained to avoid these harmful practices1 . Our Calls We
          understand from the Scottish Government’s correspondence to the
          Committee2 that it “fully supports moves by the UK Government to end
          conversion therapy” and that officials “are engaging with the UK
          Government as they develop proposals.” As this is an area of policy
          which may touch both devolved and reserved responsibilities, we
          encourage the Scottish Government to continue working with the UK
          Government to ensure any proposals developed by the UK Government
          would be effective. However, we would request that the Public
          Petitions Committee call on the Scottish Government to commit to the
          following: • Take measures, within its devolved competence, to end
          conversion therapy in Scotland, if the UK Government does not make
          timely progress on ending (including banning) conversion therapy
          across the UK. • Take measures, within its devolved competence, to
          ‘top up’ any measures introduced by the UK Government to end
          conversion therapy, if these measures would be insufficient in
          effectively rooting out conversion therapy in all its forms.
        </p>
        <h3>Our Proposals</h3>
        <h4>Legislative Ban</h4>
        <p>
          Legislation should ban conversion therapy practices that take place in
          both the public and private sphere, including those performed by
          healthcare professionals and within faith communities. According to
          the UK Government’s National LGBT Survey, most instances of conversion
          therapy in the UK (19 per cent and 51 per cent respectively) occur in
          these settings3 . A far-reaching ban is therefore necessary for
          legislation to be effective. There should be no restrictions on who is
          protected by the ban. Children and adults, deemed vulnerable or not,
          must be protected, including those who ‘consented’. A legislative ban
          should also include an equal level of protection for those who have
          been subject to conversion therapy based on sexual orientation or
          gender identity. This is particularly important given the finding of
          the National LGBT Survey that trans people were more likely to be
          offered or subjected to conversion therapies. Any intervention should
          begin from the understanding that conversion therapy is not neutral
          and is targeted at stopping LGBT people from being themselves.
          Supportive and affirming therapies that allow young people to explore
          and better understand their sexuality and gender identity should not
          be affected4. In line with recommendations by the UN, a ban must also
          include a prohibition on advertising the practice, establish a system
          of sanctions for non-compliance and a strong complaints mechanism5 .
          These measures are essential to ensure the efficient implementation of
          a legislative ban.
        </p>
        <h4>Non-Legislative Measures</h4>
        <p>
          It is vital that a specific legislative ban on sexual orientation and
          gender identity conversion therapy is accompanied by a wider package
          of work to root out these damaging practices in all their forms,
          seeking to end the practice in addition to banning it, and support
          survivors. These measures will also mitigate the risk that
          criminalising the practice might drive conversion therapy underground6
          . The UN Independent Expert made recommendations for educational
          campaigns that tackle stigma around the LGBT community, fostering an
          open dialogue with health professionals and religious groups to raise
          awareness around the harms of conversion therapy, and training for
          practitioners7 . In addition to focusing on prevention, work around
          conversion therapy should seek to support survivors of conversion
          therapy, such as establishing a helpline, counselling service, and
          developing compensation initiatives.
        </p>
        <br />
        <h4>Sources</h4>
        <p>
          1
          https://www.psychotherapy.org.uk/wp-content/uploads/2017/10/UKCP-Memorandum-of-Understanding-on-ConversionTherapy-in-the-UK.pdf
          <br />
          2
          http://www.parliament.scot/S5_PublicPetitionsCommittee/Submissions%202020/PE1817_A.pdf
          <br />
          3
          https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/722314/GEO-LGBTSurvey-Report.pdf
          <br />
          4
          https://ilga.org/downloads/ILGA_World_Curbing_Deception_world_survey_legal_restrictions_conversion_therapy.pdf
          <br />
          5 https://undocs.org/A/HRC/44/53 <br />
          6
          https://acitylawfirm.com/the-goverments-lgbt-action-plan-banning-conversion-therapy/
          <br />7 https://undocs.org/A/HRC/44/53
        </p>
      </div>
    );
  }
}

export default PetitionPage;
