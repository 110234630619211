import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TablePage from "./TablePage/TablePage";
import { Nav, Navbar } from "react-bootstrap";
import RequestPage from "./RequestPage/RequestPage";
import BackgroundPage from "./BackgroundPage/BackgroundPage";
import PetitionPage from "./PetitionPage/PetitionPage";
import PetitionConsultationPage from "./PetitionConsultationPage/PetitionConsultationPage";
import logo from "./Images/EndConversionTherapyCircle.png";
import EmailPage from "./EmailPage/EmailPage";
import MediaPage from "./MediaPage/MediaPage";
import PressPage from "./PressPage/PressPage";
import ElectedPage from "./ElectedPage/ElectedPage";

class App extends React.Component<{}, {}> {
  public componentDidMount() {
    document.title = "End Conversion Therapy";
  }

  public render(): JSX.Element {
    return (
      <>
        <Navbar bg="light" variant="light">
          <img src={logo} alt="End Conversion Therapy Logo" />
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/request">Request</Nav.Link>
            <Nav.Link href="/background">Background</Nav.Link>
            <Nav.Link href="/petition">Petition</Nav.Link>
            <Nav.Link href="/candidates">Holyrood '21 candidates</Nav.Link>
            <Nav.Link href="/press">Press</Nav.Link>
          </Nav>
        </Navbar>
        <div className="body">
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={ElectedPage} />
              <Route exact path="/request" component={RequestPage} />
              <Route exact path="/background" component={BackgroundPage} />
              <Route exact path="/petition" component={PetitionPage} />
              <Route
                exact
                path="/consultation"
                component={PetitionConsultationPage}
              />
              <Route exact path="/email" component={EmailPage} />
              <Route exact path="/media" component={MediaPage} />
              <Route exact path="/candidates" component={TablePage} />
              <Route exact path="/press" component={PressPage} />
            </Switch>
          </BrowserRouter>
        </div>
      </>
    );
  }
}

export default App;
